import React from 'react'

const UsersPage = () => {
  return (
    <div>
      comming soon
    </div>
  )
}

export default UsersPage 

// import React, { useEffect, useState, useContext } from 'react';
// import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
// import { FaChevronLeft, FaChevronRight, FaEye, FaEdit, FaTrash, FaToggleOn, FaToggleOff, FaPlus, FaSortDown } from 'react-icons/fa';
// import "../UserPage/UsersPage.css";
// import { TailSpin } from 'react-loader-spinner';
// import { useNavigate } from 'react-router-dom';
// import { SupplierProductContext } from '../../../../context/SupplierProductContext';
// import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

// const GlobalFilter = ({ searchValue, setSearchValue, setPerPage }) => {
//   return (
//     <div className="global-filter-wrapper">
//       <div className="global-filter-column">
//         <input
//           value={searchValue || ''}
//           onChange={(e) => setSearchValue(e.target.value)}
//           placeholder="Search Users..."
//           className="global-filter-input"
//         />
//       </div>
//       <div className="per-page-column">
//         <label htmlFor="perPage">Records per page: </label>
//         <select
//           id="perPage"
//           onChange={e => setPerPage(Number(e.target.value))}
//           className="records-per-page-select"
//         >
//           <option value={50}>50</option>
//           <option value={100}>100</option>
//           <option value={200}>200</option>
//           <option value={500}>500</option>
//           <option value={1000}>1000</option>
//         </select>
//       </div>
//     </div>
//   );
// };

// const PaginationControls = ({ number, totalPages, setNumber }) => {
//   const handlePrev = () => {
//     if (number > 1) setNumber(number - 1);
//   };

//   const handleNext = () => {
//     if (number < totalPages) setNumber(number + 1);
//   };

//   const handlePageChange = (e) => {
//     const page = Number(e.target.value);
//     setNumber(page);
//   };

//   return (
//     // <div className="pagination-controls">
//     <div className='pagecontrols' style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0.5rem", margin: "10px" }}>
//       <button onClick={handlePrev} disabled={number === 1} className="pagination-btn">
//         <FaChevronLeft />
//       </button>

//       Page
//       <select
//         value={number}
//         onChange={handlePageChange}
//         className="page-dropdown"
//       >
//         {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
//           <option key={page} value={page}>
//             {page}
//           </option>
//         ))}
//       </select>
//       of {totalPages}

//       <button onClick={handleNext} disabled={number === totalPages} className="pagination-btn">
//         <FaChevronRight />
//       </button>
//     </div>
//   );
// };



// const UsersPage = () => {
//   const { supplierProducts, loading, fetchSupplierProducts } = useContext(SupplierProductContext); // Use the context
//   // const [allData, setAllData] = useState([]); // Store all data from API
//   const [data, setData] = useState([]); // Paginated data
//   // const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState({});
//   const [selectAll, setSelectAll] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [number, setNumber] = useState(1);  // Track page number
//   const [totalProducts, setTotalProducts] = useState(0); // Total products for pagination
//   const [perPage, setPerPage] = useState(50); // Number of items per page
//   const [jumpToPage, setJumpToPage] = useState(''); // State to track input value for page jump
//   const [searchValue, setSearchValue] = useState(''); // Search input

//   const [linedata, setLineData] = useState([
//     { id: 1, name: 'January', rate: 100 },
//     { id: 2, name: 'Febuary', rate: 150 },
//     { id: 3, name: 'March', rate: 200 },
//     { id: 4, name: 'April', rate: 120 },
//     { id: 5, name: 'May', rate: 300 },
//     { id: 6, name: 'June', rate: 400 },
//     { id: 7, name: 'July', rate: 250 },
//   ]);

//   const navigate = useNavigate();

//   const chartData = {
//     labels: linedata.map((item) => item.name), // Labels from hardcoded data
//     datasets: [
//       {
//         label: 'User Rates',
//         data: linedata.map((item) => item.rate), // Rates from hardcoded data
//         borderColor: 'rgba(75, 192, 192, 1)',
//         backgroundColor: 'rgba(75, 192, 192, 0.2)',
//         pointBackgroundColor: 'rgba(75, 192, 192, 1)',
//         tension: 0.2,
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         position: 'top',
//       },
//     },
//     scales: {
//       x: {
//         grid: {
//           display: false,
//         },
//       },
//       y: {
//         beginAtZero: true,
//       },
//     },
//   };

//   useEffect(() => {
//     const applySearchAndPagination = () => {
//       const filteredData = supplierProducts.filter((item) =>
//         Object.values(item).some(
//           (value) =>
//             typeof value === 'string' &&
//             value.toLowerCase().includes(searchValue.toLowerCase())
//         )
//       );

//       setTotalProducts(filteredData.length);

//       const start = (number - 1) * perPage;
//       const end = start + perPage;

//       setData(filteredData.slice(start, end));
//     };

//     applySearchAndPagination();
//   }, [supplierProducts, searchValue, number, perPage]);

//   useEffect(() => {
//     setNumber(1);
//   }, [searchValue]);


//   const handleSelectAll = () => {
//     setSelectAll(!selectAll);
//     const newSelectedRows = {};
//     data.forEach((item, index) => {
//       newSelectedRows[index] = !selectAll;
//     });
//     setSelectedRows(newSelectedRows);
//   };

//   const handleRowSelect = (rowIndex) => {
//     setSelectedRows(prev => ({
//       ...prev,
//       [rowIndex]: !prev[rowIndex],
//     }));
//   };
//   const handleStatusToggle = async (productId, currentStatus) => {
//     const supplier_id = 2405;
//     const newStatus = currentStatus === 'Active' || currentStatus === '1' ? 'Deactive' : 'Active'; // Toggle between 'Active' and 'Deactive'

//     // Show confirmation dialog to the user
//     const userConfirmed = window.confirm(
//       `Are you sure you want to ${newStatus === 'Active' ? 'activate' : 'deactivate'} this product?`
//     );

//     if (!userConfirmed) {
//       return; // If user cancels, don't proceed
//     }

//     try {
//       const formData = new FormData();
//       formData.append('supplier_id', supplier_id);
//       formData.append('product_id', productId);
//       formData.append('status', newStatus); // Pass the new status as 'Active' or 'Deactive'

//       // const response = await fetch('http://offerli.listapp.in/api/supplier/toggleProduct', {
//       //   method: 'POST',
//       //   body: formData,
//       // });
//       const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/toggleProduct`, {
//         method: 'POST',
//         body: formData,
//       });

//       const result = await response.json();
//       fetchSupplierProducts();
//       if (result.status === "true") {
//         // Update the data state with the new status
//         setData(prevData =>
//           prevData.map(item =>
//             item.product_id === productId ? { ...item, status: newStatus === 'Active' ? '1' : '0' } : item
//           )
//         );
//         alert(`Product ${newStatus === 'Active' ? 'activated' : 'deactivated'} successfully.`);
//       } else {
//         console.error('Error toggling product status:', result.message);
//       }
//     } catch (error) {
//       console.error('Error during status toggle API call:', error);
//     }
//   };

//   const handleView = (product) => {
//     setSelectedProduct(product);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedProduct(null);
//   };
//   const columns = React.useMemo(
//     () => [
//       {
//         Header: (
//           <input
//             type="checkbox"
//             checked={selectAll}
//             onChange={handleSelectAll}
//           />
//         ),
//         id: 'select',
//         disableSortBy: true, // Disable sorting for this column
//         Cell: ({ row }) => (
//           <input
//             type="checkbox"
//             checked={selectedRows[row.index] || false}
//             onChange={() => handleRowSelect(row.index)}
//           />
//         ),
//       },
//       {
//         Header: 'S No.',
//         id: 'serial_no',
//         accessor: (row, i) => (number - 1) * perPage + i + 1, // Adjust serial number based on page
//         sortType: 'basic', // Enable sorting for serial number
//         Cell: ({ row }) => (number - 1) * perPage + row.index + 1, // Display adjusted serial number
//       },
//       { Header: 'Business Name', accessor: 'product_name' },
//       { Header: 'Contact Number', accessor: 'Scheme' },
//       { Header: 'Address', accessor: 'mrp' },
//       { Header: 'License Number', accessor: 'rate' },
//       { Header: 'GSTIN', accessor: 'category' },
//       {
//         Header: 'Status',
//         accessor: 'status',
//         Cell: ({ value }) => (
//           <span className={`status-box ${value === '1' ? 'active' : 'deactive'}`}>
//             {value === '1' ? 'Active' : 'Deactive'}
//           </span>
//         ),
//       },
//       {
//         Header: 'Actions',
//         disableSortBy: true, // Disable sorting for this column
//         Cell: ({ row }) => {
//           const product = row?.original;
//           if (!product) return null;

//           return (
//             <div className="action-buttons">
//               <button title="View Product" className="action-btn view" onClick={() => handleView(product)}>
//                 <FaEye />
//               </button>
//             </div>
//           );
//         },
//       },
//     ],
//     [selectedRows, selectAll, number, perPage, searchValue]
//   );

//   const handleEdit = (product) => {
//     navigate('/edit-product', { state: { product } }); // Use `useNavigate` to navigate
//   };
//   const handleDelete = (productId) => {
//     setData(prevData => prevData.filter(item => item.product_id !== productId));
//   };

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//   } = useTable(
//     {
//       columns,
//       data,
//       // initialState: { pageIndex: number - 1, pageSize: perPage },
//       initialState: { pageSize: perPage },
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );
//   return (
//     <div className="products-page">
//       <div className="table-container">
//         <h1 className="page-title">Users</h1>
//         {/* <div style={{ height: '400px', width: '100%' }}>
//             <Line data={chartData} options={chartOptions} />
//           </div> */}
//         <GlobalFilter searchValue={searchValue} setSearchValue={setSearchValue} setPerPage={setPerPage} />
//         {loading ? (
//           <div className="loading">
//             <TailSpin color="#00BFFF" height={80} width={80} />
//           </div>
//         ) : (
//           <>
//             <div className="table-scroll-wrapper">
//               <table {...getTableProps()} className="product-table">
//                 <thead>
//                   {headerGroups.map((headerGroup) => (
//                     <tr {...headerGroup.getHeaderGroupProps()}>
//                       {headerGroup.headers.map((column) => (
//                         <th
//                           {...column.getHeaderProps()}
//                           className="table-header"
//                         >
//                           {/* <div className="header-content"> */}
//                           <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
//                             {column.render("Header")}
//                             {/* Only show sort icons if sorting is enabled */}
//                             {!column.disableSortBy && (
//                               <div className="sort-icons">
//                                 <IoIosArrowUp
//                                   className={`sort-icon ${column.isSorted && !column.isSortedDesc ? "active-sort" : ""}`}
//                                   onClick={() => {
//                                     column.toggleSortBy(false); // Ascending order
//                                   }}
//                                 />
//                                 <IoIosArrowDown
//                                   className={`sort-icon ${column.isSorted && column.isSortedDesc ? "active-sort" : ""}`}
//                                   onClick={() => {
//                                     column.toggleSortBy(true); // Descending order
//                                   }}
//                                 />
//                               </div>
//                             )}
//                           </div>
//                         </th>
//                       ))}
//                     </tr>
//                   ))}
//                 </thead>
//                 <tbody {...getTableBodyProps()}>
//                   {rows.map(row => {
//                     prepareRow(row);
//                     return (
//                       <tr {...row.getRowProps()}>
//                         {row.cells.map(cell => {
//                           return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
//                         })}
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </div>
//             <div className="pagination-controls">
//               <div className="entries-info">
//                 <p>
//                   Showing {(number - 1) * perPage + 1} to {Math.min(number * perPage, totalProducts)} of {totalProducts} entries
//                 </p>
//               </div>
//               <PaginationControls
//                 number={number}
//                 totalPages={Math.ceil(totalProducts / perPage)}
//                 setNumber={setNumber}
//                 jumpToPage={jumpToPage}
//                 setJumpToPage={setJumpToPage}
//               />
//             </div>
//           </>
//         )}
//       </div>
//       {/* {showModal && <ProductDetails product={selectedProduct} handleClose={handleCloseModal} />} */}
//     </div>
//   );
// };

// export default UsersPage;
