import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table';
import { FaChevronLeft, FaChevronRight, FaEye, FaEdit, FaTrash, FaToggleOn, FaToggleOff, FaPlus, FaSortDown, FaMinus } from 'react-icons/fa';
import "../ProductPage/ProductsPage.css";
import ProductDetails from './ProductDetails';
import { TailSpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { SupplierProductContext } from '../../../../context/SupplierProductContext';
import { FaSortUp } from 'react-icons/fa6';
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Register the required chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const GlobalFilter = ({ searchValue, setSearchValue, setPerPage, fetchSupplierProducts }) => {
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
  const [advancedSearchFields, setAdvancedSearchFields] = useState({
    productName: '',
    category: '',
    companyName: '',
  });

  const handleAdvancedSearchToggle = () => {
    setAdvancedSearchVisible(!advancedSearchVisible);
  };

  const handleAdvancedSearchChange = (e) => {
    setAdvancedSearchFields({
      ...advancedSearchFields,
      [e.target.name]: e.target.value,
    });
  };

  const handleAdvancedSearchSubmit = async () => {
    try {
      // Create a new FormData object
      const formData = new FormData();

      const supId = JSON.parse(localStorage.getItem('supplier_id'));

      // Append form fields to the FormData object
      formData.append('supplier_id', supId);
      formData.append('companyName', advancedSearchFields.companyName);
      formData.append('composition', advancedSearchFields.composition);
      formData.append('category', advancedSearchFields.category);

      // Send the form data in the request
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/searchSupplierProduct`, {
        method: 'POST',
        body: formData, // Send FormData in the request body
      });

      const result = await response.json();
      if (result.status === "true") {
        toast.success('Advanced search results fetched successfully!');
        // You can update your data with the search results
        setSearchValue(''); // Reset the basic search value
        fetchSupplierProducts(); // Fetch new data based on advanced search criteria
      } else {
        toast.error('Error fetching advanced search results: ' + result.message);
      }
    } catch (error) {
      console.error('Error during advanced search API call:', error);
      toast.error('Failed to fetch search results. Please try again later.');
    }
  };

  return (
    <div className="global-filter-wrapper">
      <div className="global-filter-column">
        <ToastContainer />
        <input
          value={searchValue || ''}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search Products..."
          className="global-filter-input"
        />
        {/* <button
          onClick={handleAdvancedSearchToggle}
          className="advanced-search-btn"
        >
          Advanced Search
        </button> */}

        {/* Advanced Search Inputs */}
        {advancedSearchVisible && (
          <div className="advanced-search-fields">
            <input
              type="text"
              name="companyName"
              value={advancedSearchFields.companyName}
              onChange={handleAdvancedSearchChange}
              placeholder="Company Name"
              className="advanced-search-input"
            />
            <input
              type="text"
              name="composition"
              // value={advancedSearchFields.composition}
              // value={advancedSearchFields.composition}
              value={advancedSearchFields.detail}
              onChange={handleAdvancedSearchChange}
              placeholder="Composition"
              className="advanced-search-input"
            />
            <input
              type="text"
              name="category"
              value={advancedSearchFields.category}
              onChange={handleAdvancedSearchChange}
              placeholder="Category"
              className="advanced-search-input"
            />
            <button
              onClick={handleAdvancedSearchSubmit}
              className="apply-search-btn"
            >
              Apply Search
            </button>
          </div>
        )}
      </div>
      <div className="per-page-column">
        <label htmlFor="perPage">Records per page: </label>
        <select
          id="perPage"
          onChange={e => setPerPage(Number(e.target.value))}
          className="records-per-page-select"
        >
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
        </select>
      </div>
    </div>
  );
};

const PaginationControls = ({ number, totalPages, setNumber }) => {
  const [inputValue, setInputValue] = useState(number); // Local state for input field

  useEffect(() => {
    setInputValue(number); // Update input value when the current page number changes
  }, [number]);

  const handlePrev = () => {
    if (number > 1) setNumber(number - 1);
  };

  const handleNext = () => {
    if (number < totalPages) setNumber(number + 1);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update the input value as the user types
    const page = Number(e.target.value);
    if (page >= 1 && page <= totalPages) {
      setNumber(page); // Update the page number immediately if input is valid
    }
  };

  const handleBlur = () => {
    const page = Number(inputValue);
    if (page >= 1 && page <= totalPages) {
      setNumber(page); // Update the page number if input is valid
    } else {
      setInputValue(number); // If invalid, revert to current page number
    }
  };

  const handleKeyDown = (e) => {
    const page = Number(inputValue);
    if (e.key === 'Enter') {
      // Allow pressing enter to apply the page change
      if (page >= 1 && page <= totalPages) {
        setNumber(page); // Update the page number if valid
      } else {
        setInputValue(number); // Revert to current page if invalid
      }
    } else if (e.key === 'ArrowDown') {
      // If down arrow is pressed, move to the next page if not on the last page
      if (number < totalPages) {
        setNumber(number + 1);
      }
    } else if (e.key === 'ArrowUp') {
      // If up arrow is pressed, move to the previous page if not on the first page
      if (number > 1) {
        setNumber(number - 1);
      }
    }
  };

  return (
    <div
      className="pagecontrols"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.5rem',
        margin: '10px',
      }}
    >
      <button onClick={handlePrev} disabled={number === 1} className="pagination-btn">
        <FaChevronLeft />
      </button>
      Page
      <input
        type="text"
        value={inputValue} // Set value to the state
        onChange={handleInputChange}
        onBlur={handleBlur} // Validate input when focus leaves
        onKeyDown={handleKeyDown} // Validate input on Enter key
        className="page-input"
        style={{
          width: '25px',
          textAlign: 'center',
        }}
        placeholder="Type page"
      />
      of {totalPages}
      <button onClick={handleNext} disabled={number === totalPages} className="pagination-btn">
        <FaChevronRight />
      </button>
    </div>
  );
};

const ProductsPage = () => {
  const { supplierId, supplierProducts, loading, fetchSupplierProducts } = useContext(SupplierProductContext); // Use the context
  const [data, setData] = useState([]); // Paginated data
  const [selectedRows, setSelectedRows] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [number, setNumber] = useState(1);  // Track page number
  const [totalProducts, setTotalProducts] = useState(0); // Total products for pagination
  const [perPage, setPerPage] = useState(50); // Number of items per page
  const [jumpToPage, setJumpToPage] = useState(''); // State to track input value for page jump
  const [searchValue, setSearchValue] = useState(''); // Search input
  const [editingCell, setEditingCell] = useState(null);  // Track which cell is being edited
  const [editedValue, setEditedValue] = useState('');    // Track the new value for editing-

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');


  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);  // Set the clicked image URL
    setShowImageModal(true);     // Open the modal
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);  // Close the modal
    setSelectedImage('');      // Clear selected image
  };

  const handleEditClick = (rowIndex, columnId, value) => {
    setEditingCell({ rowIndex, columnId });
    setEditedValue(value);  // Initialize with current value of the cell
  }

  const handleSaveClick = async (rowIndex, columnId) => {
    try {
      const updatedData = [...data];
      const product = updatedData[rowIndex]; // Get the current product data from the table

      // If no change in the value, prevent the API call
      if (product[columnId] === editedValue) {
        setEditingCell(null); // Reset the editing state
        return; // Don't update if the value hasn't changed
      }

      // Update the product data in the table
      product[columnId] = editedValue; // Modify the specific column value
      setData(updatedData); // Update the table with the modified data

      // Create FormData to send the updated data to the server
      const formData = new FormData();
      formData.append('product_id', product.product_id);
      formData.append('supplier_id', supplierId); // Assuming supplierId comes from context
      formData.append('supp_prod_id', product.supp_prod_id); // Assuming supplierId comes from context
      formData.append('product_name', product.product_name);
      formData.append('scheme', product.scheme);
      formData.append('mrp', product.mrp);
      formData.append('rate', product.rate);
      formData.append('category', product.category);
      formData.append('current_stock', product.current_stock);
      formData.append('detail', product.detail);
      formData.append('company_name', product.company_name);
      formData.append('status', product.status);  // Make sure you're sending all necessary fields

      // Make the API call to update the product in the database
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/updateProduct`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.status === "true") {
        setEditingCell(null); // Reset editing state on success
        toast.success('Product details updated successfully!');
      } else {
        alert('Error updating product details: ' + result.message);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      alert('Failed to update product details. Please try again later.');
    }
  };

  const handleCancelClick = () => {
    setEditingCell(null);
  };
  const handleInputChange = (e) => {
    setEditedValue(e.target.value);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const chartData = {
    labels: ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August'],
    datasets: [
      {
        label: 'Products by Months',
        data: [12, 19, 7, 15, 12, 19, 7, 15], // Example data
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const newSelectedRows = {};
    data.forEach((item, index) => {
      newSelectedRows[index] = !selectAll;
    });
    setSelectedRows(newSelectedRows);
  };

  const handleRowSelect = (rowIndex) => {
    setSelectedRows(prev => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const handleBulkDelete = async () => {
    // Collect selected product IDs
    const selectedProductIds = Object.keys(selectedRows)
      .filter(rowIndex => selectedRows[rowIndex])
      .map(rowIndex => data[rowIndex].product_id);

    if (selectedProductIds.length === 0) {
      alert("Please select at least one product to delete.");
      return;
    }

    // Show confirmation dialog
    const userConfirmed = window.confirm("Are you sure you want to delete the selected products?");
    if (!userConfirmed) {
      return;
    }

    try {
      // Loop through each selected product and delete it individually
      for (let productId of selectedProductIds) {
        const formData = new FormData();
        formData.append('supplier_id', supplierId);
        formData.append('product_ids', productId); // Send each product_id individually

        const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/deleteProduct`, {
          method: 'POST',
          body: formData,
        });

        const result = await response.json();
        if (result.status === "true") {
          // Update the UI by removing the deleted product from the data
          setData(prevData => prevData.filter(item => item.product_id !== productId));
        } else {
          alert(`Error deleting product with ID ${productId}: ${result.message}`);
        }
      }

      // Reset selected rows after deletion
      setSelectedRows({});

      toast.success('Selected products deleted successfully!');
    } catch (error) {
      console.error('Error during bulk delete:', error);
      alert('Failed to delete products. Please try again later.');
    }
  };

  const handleBulkDeactivate = async () => {
    // Collect selected product IDs
    const selectedProductIds = Object.keys(selectedRows)
      .filter(rowIndex => selectedRows[rowIndex])
      .map(rowIndex => data[rowIndex].product_id);

    if (selectedProductIds.length === 0) {
      alert("Please select at least one product to deactivate.");
      return;
    }

    // Show confirmation dialog
    const userConfirmed = window.confirm("Are you sure you want to deactivate the selected products?");
    if (!userConfirmed) {
      return;
    }

    try {
      // Loop through each selected product and deactivate it individually
      for (let productId of selectedProductIds) {
        const formData = new FormData();
        formData.append('supplier_id', supplierId); // Use supplierId dynamically
        formData.append('product_ids', productId); // Send each product_id individually
        formData.append('status', 'deactive'); // Assuming 'Deactive' is the status for deactivated products

        const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/toggleProduct`, {
          method: 'POST',
          body: formData,
        });

        const result = await response.json();
        if (result.status === "true") {
          // Update the state to reflect the new status for the deactivated product
          setData(prevData =>
            prevData.map(item =>
              item.product_id === productId
                ? { ...item, status: 'deactive' } // Update the status for the deactivated product
                : item
            )
          );
          setSelectedRows({});
        } else {
          alert(`Error deactivating product with ID ${productId}: ${result.message}`);
        }
      }

      fetchSupplierProducts();
      toast.success('Selected products deactivated successfully!');
    } catch (error) {
      console.error('Error during bulk deactivate:', error);
      alert('Failed to deactivate products. Please try again later.');
    }
  };

  const handleBulkActivate = async () => {
    // Collect selected product IDs
    const selectedProductIds = Object.keys(selectedRows)
      .filter(rowIndex => selectedRows[rowIndex])
      .map(rowIndex => data[rowIndex].product_id);

    if (selectedProductIds.length === 0) {
      alert("Please select at least one product to activate.");
      return;
    }

    // Show confirmation dialog
    const userConfirmed = window.confirm("Are you sure you want to activate the selected products?");
    if (!userConfirmed) {
      return;
    }

    try {
      // Loop through each selected product and activate it individually
      for (let productId of selectedProductIds) {
        const formData = new FormData();
        formData.append('supplier_id', supplierId); // Use supplierId dynamically
        formData.append('product_ids', productId); // Send each product_id individually
        formData.append('status', 'active'); // Assuming '1' is the status for activated products

        const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/toggleProduct`, {
          method: 'POST',
          body: formData,
        });

        const result = await response.json();
        if (result.status === "true") {
          // Update the state to reflect the new status for the activated product
          setData(prevData =>
            prevData.map(item =>
              item.product_id === productId
                ? { ...item, status: 'active' } // Update the status for the activated product
                : item
            )
          );
        } else {
          alert(`Error activating product with ID ${productId}: ${result.message}`);
        }
      }

      // Reset selected rows after activation
      setSelectedRows({});
      // fetchSupplierProducts(supplierId);

      toast.success('Selected products activated successfully!');
    } catch (error) {
      console.error('Error during bulk activate:', error);
      alert('Failed to activate products. Please try again later.');
    }
  };

  // Check if any rows are selected
  const isAnyRowSelected = Object.values(selectedRows).some(value => value === true);

  useEffect(() => {
    fetchSupplierProducts(supplierId);
  }, [location]);


  useEffect(() => {
    const applySearchAndPagination = () => {
      // Filter the data based on the search input
      const filteredData = supplierProducts.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === 'string' &&
            value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
      setTotalProducts(filteredData.length); // Set total products
      const newTotalPages = Math.ceil(filteredData.length / perPage);
      const currentPage = number > newTotalPages ? newTotalPages : number;
      setNumber(currentPage);
      const start = (currentPage - 1) * perPage;
      const end = start + perPage;

      setData(filteredData.slice(start, end)); // Update paginated data
    };

    applySearchAndPagination();
  }, [supplierProducts, searchValue, number, perPage]);

  useEffect(() => {
    setNumber(1);
  }, [searchValue]);

  useEffect(() => {
    const newTotalPages = Math.ceil(totalProducts / perPage);
    if (number > newTotalPages) {
      setNumber(1, newTotalPages); // Adjust to the last available page if current page exceeds total pages
    }
  }, [searchValue, perPage, totalProducts]);
  const handleView = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
        ),
        id: 'select',
        disableSortBy: true, // Disable sorting for this column
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedRows[row.index] || false}
            onChange={() => handleRowSelect(row.index)}
          />
        ),
      },
      {
        Header: 'S No.',
        id: 'serial_no',
        accessor: (row, i) => (number - 1) * perPage + i + 1,
        sortType: 'basic',
        Cell: ({ row }) => (number - 1) * perPage + row.index + 1,
      },
      {
        Header: 'Product ID',
        // accessor: 'product_id',
        accessor: 'supp_prod_id',
        sortType: 'basic',
        Cell: ({ row }) => <span>{row.values.supp_prod_id}</span>,
      },
      {
        Header: 'Product Image',
        accessor: 'product_image', // If you have dynamic image data
        disableSortBy: true,
        Cell: ({ value }) => (
          <img
            src={value ? value : '//himalayawellness.in/cdn/shop/products/Complete-Care.jpg?v=1622100609'} // Fallback to the default Himalaya image if no value
            alt="Product"
            onError={(e) => e.target.src = 'https://via.placeholder.com/150'} // Fallback in case of an image load error
            style={{ width: '50px', height: '50px', objectFit: 'cover', cursor: 'pointer' }}
            onClick={() => handleImageClick(value || '//himalayawellness.in/cdn/shop/products/Complete-Care.jpg?v=1622100609')} // Ensure the default image is used if no dynamic URL
          />
        ),
      },
      {
        Header: 'Product Name',
        accessor: 'product_name',
        Cell: ({ row, column, cell }) => {
          const isEditing =
            editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;

          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };

          return (
            // <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onClick={handleClickCell}> {/* Use cell.getCellProps here */}
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}> {/* Use cell.getCellProps here */}
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="text"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)} // Save when the input loses focus
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              ) : (
                <span>{row.values[column.id]}</span>  // Display the cell text here
              )}
            </td>
          );
        },
      },
      {
        Header: 'Scheme',
        accessor: 'scheme',
        Cell: ({ row, column, cell }) => {
          const isEditing = editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;

          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);  // Call handleSaveClick on Enter key press
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };
          return (
            // <span>
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}>
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="text"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)}
                  onKeyDown={handleKeyDown}  // Listen for keydown event
                  autoFocus
                />
              ) : (
                // <span onClick={() => handleEditClick(row.index, column.id, row.values[column.id])}>
                <span>
                  {row.values[column.id]}
                </span>
              )}
              {/* </span> */}
            </td>
          );
        },
      },
      {
        Header: 'MRP',
        accessor: 'mrp',
        Cell: ({ row, column, cell }) => {
          const isEditing = editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;
          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);  // Call handleSaveClick on Enter key press
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };

          return (
            // <span>
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}>
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="number"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)}
                  onKeyDown={handleKeyDown}  // Listen for keydown event
                  autoFocus
                />
              ) : (
                // <span onClick={() => handleEditClick(row.index, column.id, row.values[column.id])}>
                <span>
                  {row.values[column.id]}
                </span>
              )}
              {/* </span> */}
            </td>
          );
        },
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        Cell: ({ row, column, cell }) => {
          const isEditing = editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;

          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);  // Call handleSaveClick on Enter key press
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };

          return (
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}>
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="number"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)}
                  onKeyDown={handleKeyDown}  // Listen for keydown event
                  autoFocus
                />
              ) : (
                // <span onClick={() => handleEditClick(row.index, column.id, row.values[column.id])}>
                <span>
                  {row.values[column.id]}
                </span>
              )}
            </td>
          );
        },
      },
      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ row, column, cell }) => {
          const isEditing = editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;

          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);  // Call handleSaveClick on Enter key press
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };

          return (
            // <span>
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}>
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="text"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)} // Save when user clicks outside
                  onKeyDown={handleKeyDown}  // Listen for keydown event
                  autoFocus
                />
              ) : (
                // <span onClick={() => handleEditClick(row.index, column.id, row.values[column.id])}>
                <span>
                  {row.values[column.id]}
                </span>
              )}
            </td>
          );
        },
      },
      {
        Header: 'Current Stock',
        accessor: 'current_stock',
        Cell: ({ row, column, cell }) => {
          const isEditing = editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;

          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);  // Call handleSaveClick on Enter key press
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };

          return (
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}>
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="number"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)} // Save when user clicks outside
                  onKeyDown={handleKeyDown}  // Listen for keydown event
                  autoFocus
                />
              ) : (
                // <span onClick={() => handleEditClick(row.index, column.id, row.values[column.id])}>
                <span>
                  {row.values[column.id]}
                </span>
              )}
            </td>
          );
        },
      },
      {
        // Header: 'Composition',
        Header: 'Detail',
        // accessor: 'composition',
        accessor: 'detail',
        Cell: ({ row, column, cell }) => {
          const isEditing = editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;

          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);  // Call handleSaveClick on Enter key press
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };

          return (
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}>
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="text"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)} // Save when user clicks outside
                  onKeyDown={handleKeyDown}  // Listen for keydown event
                  autoFocus
                />
              ) : (
                // <span onClick={() => handleEditClick(row.index, column.id, row.values[column.id])}>
                <span>
                  {row.values[column.id]}
                </span>
              )}
            </td>
          );
        },
      },
      {
        Header: 'Company Name',
        accessor: 'company_name',
        Cell: ({ row, column, cell }) => {
          const isEditing = editingCell?.rowIndex === row.index && editingCell?.columnId === column.id;
          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSaveClick(row.index, column.id);  // Call handleSaveClick on Enter key press
            }
          };

          const handleClickCell = () => {
            handleEditClick(row.index, column.id, row.values[column.id]);
          };
          return (
            <td style={{ height: '3rem', width: '7rem', border: 'none' }} {...cell.getCellProps()} onDoubleClick={handleClickCell}>
              {isEditing ? (
                <input
                  style={{ width: '6rem', height: '5rem', border: 'none', outline: 'none' }}
                  type="text"
                  value={editedValue}
                  onChange={handleInputChange}
                  onBlur={() => handleSaveClick(row.index, column.id)} // Save when user clicks outside
                  onKeyDown={handleKeyDown}  // Listen for keydown event
                  autoFocus
                />
              ) : (
                // <span onClick={() => handleEditClick(row.index, column.id, row.values[column.id])}>
                <span>
                  {row.values[column.id]}
                </span>
              )}
            </td>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => {
          const product = row?.original; // Access the product object
          if (!product) return null;

          const handleChangeStatus = async (event) => {
            const newStatus = event.target.value; // Get the selected status

            // Show confirmation dialog
            const userConfirmed = window.confirm(
              `Are you sure you want to ${newStatus === 'active' ? 'activate' : 'deactivate'} this product?`
            );
            if (!userConfirmed) return;

            try {
              const formData = new FormData();
              formData.append('supplier_id', supplierId); // Use supplierId dynamically
              formData.append('product_ids', product.product_id);
              formData.append('status', newStatus); // Pass the new status

              const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/toggleProduct`, {
                method: 'POST',
                body: formData,
              });

              const result = await response.json();
              if (result.status === "true") {
                // Update the state with the new status
                setData((prevData) =>
                  prevData.map((item) =>
                    item.product_id === product.product_id
                      ? { ...item, status: newStatus }
                      : item
                  )
                );
                alert(`Product ${newStatus === 'active' ? 'activated' : 'deactivated'} successfully.`);
              } else {
                console.error('Error toggling product status:', result.message);
                alert('Failed to update product status.');
              }
            } catch (error) {
              console.error('Error during status toggle API call:', error);
              alert('An error occurred while updating the status. Please try again.');
            }
          };

          return (
            <select
              value={product.status} // Ensure correct value is bound
              onChange={handleChangeStatus}
              style={{ padding: '5px', borderRadius: '4px', cursor: 'pointer' }}
            >
              <option value="active">Active</option>
              <option value="deactive">Deactive</option>
            </select>
          );
        },
      },
      {
        Header: 'Create Date',
        accessor: 'created_at',
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleDateString();
          return formattedDate;
        },
      },
      {
        Header: 'Actions',
        disableSortBy: true, // Disable sorting for this column
        Cell: ({ row }) => {
          const product = row?.original;
          if (!product) return null;

          return (
            <div className="action-buttons">
              <button title="View Product" className="action-btn view" onClick={() => handleView(product)}>
                <FaEye />
              </button>
              <button title="Edit Product" className="action-btn edit" onClick={() => handleEdit(product)}>
                <FaEdit />
              </button>
              <button title="Delete Product" className="action-btn delete" onClick={() => handleDelete(product.product_id)}>
                <FaTrash />
              </button>
            </div>
          );
        },
      },
    ],
    [selectedRows, selectAll, number, perPage, searchValue, editingCell, editedValue, data]
  );

  const handleEdit = (product) => {
    navigate('/edit-product', { state: { product } }); // Use `useNavigate` to navigate
  };

  const handleDelete = async (productId) => {
    // Create FormData
    const formData = new FormData();
    formData.append('product_ids', productId);
    formData.append('supplier_id', supplierId);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/deleteProduct`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setData((prevData) => prevData.filter((item) => item.product_id !== productId));
        toast.success("Product deleted successfully");
        // console.log('Product deleted successfully');
      } else {
        toast.error("Failed to delete the product");
        // console.error('Failed to delete the product:', await response.json());
      }
    } catch (error) {
      console.error('Error deleting the product:', error);
    }
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: number - 1, pageSize: perPage },
      initialState: { pageSize: perPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // Add some basic styling for the modal (you can customize this as needed)
  const modalStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const imageStyles = {
    maxWidth: '90%',
    maxHeight: '80%',
    objectFit: 'contain',
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '30px',
    color: 'white',
    cursor: 'pointer',
  };
  return (
    <div className="products-page">
      <div className="table-container">
        <h1 className="page-title">Products</h1>
        {/* Bar Chart */}
        {/* <div className="chart-cont">
          <Bar data={chartData} options={chartOptions} />
        </div> */}
        <GlobalFilter searchValue={searchValue} setSearchValue={setSearchValue} setPerPage={setPerPage} />
        <div className="add-buttons-wrapper">
          <button
            onClick={handleBulkDelete}
            disabled={!isAnyRowSelected}
            className={`bulk-button ${isAnyRowSelected ? 'add-bulk-product-btn' : 'disabled'}`}
          >
            <FaMinus className="button-icon" /> Bulk Delete
          </button>
          <button
            onClick={handleBulkDeactivate} // Call the bulk deactivate logic
            disabled={!isAnyRowSelected} // Disable button when no rows are selected
            className={`bulk-button ${isAnyRowSelected ? 'add-bulk-product-btn' : 'disabled'}`}
          >
            {/* Bulk Deactivate */}
            Out Of Stock
          </button>
          <button
            onClick={handleBulkActivate} // Call the bulk activate logic
            disabled={!isAnyRowSelected} // Disable button when no rows are selected
            className={`bulk-button ${isAnyRowSelected ? 'add-bulk-product-btn' : 'disabled'}`}
          >
            Bulk Activate
          </button>
          <hr />
          <button
            className="add-product-btn"
            onClick={() => navigate('/add-product')} // Navigate to Add Product page
          >
            <FaPlus className="button-icon" /> Add Product
          </button>
          <button
            className="add-bulk-product-btn"
            onClick={() => navigate('/add-bulk-product')} // Navigate to Add Bulk Product page
          >
            <FaPlus className="button-icon" /> Bulk Products
          </button>
        </div>
        {loading ? (
          <div className="loading">
            <TailSpin color="#00BFFF" height={80} width={80} />
          </div>
        ) : rows.length === 0 ? (
          <div className="no-products-found">
            <img
              src="/images/no product found.avif"
              alt="No Products Found"
              className="no-products-image"
            />
            <p>No products available. Please try a different search query.</p>
          </div>
        ) : (
          <>
            {/* <div className="table-scroll-wrapper">
              <table {...getTableProps()} className="product-table">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            !column.disableSortBy ? column.getSortByToggleProps() : {}
                          )}
                          className="table-header"
                        >
                          <div className="header-content">
                            {column.render("Header")}
                            {!column.disableSortBy && (
                              <div className="sort-icons">
                                <IoIosArrowDown
                                  className={`sort-icon ${column.isSorted ? "active-sort" : ""} ${column.isSortedDesc ? "sorted-desc" : "sorted-asc"
                                    }`}
                                />
                              </div>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps({
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
            <div className="table-scroll-wrapper">
              <div className="table-container">
                <table {...getTableProps()} className="product-table">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              !column.disableSortBy ? column.getSortByToggleProps() : {}
                            )}
                            className="table-header"
                          >
                            <div className="header-content">
                              {column.render("Header")}
                              {!column.disableSortBy && (
                                <div className="sort-icons">
                                  <IoIosArrowDown
                                    className={`sort-icon ${column.isSorted ? "active-sort" : ""} ${column.isSortedDesc ? "sorted-desc" : "sorted-asc"}`}
                                  />
                                </div>
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* Modal to display the image */}
                {showImageModal && (
                  <div className="modal" style={modalStyles}>
                    <div className="modal-content">
                      <span className="close-btn" onClick={handleCloseImageModal}>&times;</span>
                      <img src={selectedImage} alt="Selected Product" style={imageStyles} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="pagination-controls">
              <div className="entries-info">
                <p>
                  Showing {(number - 1) * perPage + 1} to {Math.min(number * perPage, totalProducts)} of {totalProducts} entries
                </p>
              </div>
              <PaginationControls
                number={number}
                totalPages={Math.ceil(totalProducts / perPage)}
                setNumber={setNumber}
                jumpToPage={jumpToPage}
                setJumpToPage={setJumpToPage}
              />
            </div>
          </>
        )}
      </div>
      {showModal && <ProductDetails product={selectedProduct} handleClose={handleCloseModal} />}
    </div >
  );
};

export default ProductsPage;