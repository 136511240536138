import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Register.css';
import { useLocation } from 'react-router-dom';
import { SupplierProductContext } from '../../../context/SupplierProductContext';

function Register({ setIsAuthenticated }) {
    const location = useLocation();
    const prefilledPhoneNumber = location.state?.phoneNumber || ''; // Default to an empty string if no phone number is passed
    const [phoneNumber, setPhoneNumber] = useState(prefilledPhoneNumber);
    const [step, setStep] = useState(1); // Step 1: Business Name & Password, Step 2: OTP
    const [businessName, setBusinessName] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [timer, setTimer] = useState(120); // Timer set to 120 seconds
    const [otpSent, setOtpSent] = useState(false); // Flag to track if OTP was sent
    const [supplierId, setNewSupplier] = useState(''); // New state for supplier_id

    const { setSupplierId } = useContext(SupplierProductContext);
    const { setShopName } = useContext(SupplierProductContext);
    const { setNumber } = useContext(SupplierProductContext);
    const { setOrderLink } = useContext(SupplierProductContext);

    const navigate = useNavigate();

    const handleRegisterUser = async () => {
        const formData = new FormData();
        formData.append('name', businessName);
        formData.append('mobile_number', phoneNumber);
        formData.append('password', password);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/registeration`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data?.status === "true") {
                alert('Registration Successful!');
                setStep(2);
                const supplier_id = data.data[0].supplier_id;
                // setNewSupplier(supplier_id);
                // localStorage.setItem('supplier_id', supplier_id); 
                // const supplier_id = data.data.supplier_id; // Extract supplier_id from the API response
                setSupplierId(supplier_id); // Store supplier_id in state
                return true;
            } else {
                setErrorMessage(data.message || 'Registration failed.');
                return false;
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
            console.error('Error registering user:', error);
            return false;
        }
    };
    const handleSendOtp = async () => {
        const formData = new FormData();
        formData.append('supplier_id', supplierId); // Use the supplier_id stored in the state
        formData.append('mobile_number', phoneNumber);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/sendOtp`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                setOtpSent(true); // OTP sent successfully
                alert('OTP sent successfully!');
                return true; // OTP sent successfully
            } else {
                setErrorMessage(data.message || 'Failed to send OTP.');
                return false;
            }
        } catch (error) {
            setErrorMessage('An error occurred while sending OTP. Please try again later.');
            console.error('Error sending OTP:', error);
            return false;
        }
    };

    const handleNext = async (e) => {
        e.preventDefault();
        if (step === 1) {
            try {
                const registrationSuccess = await handleRegisterUser(); // Register user via API call
                if (registrationSuccess) {
                    setStep(2); // Proceed to the next step (e.g., show OTP input)
                }
            } catch (error) {
                setErrorMessage('Registration failed. Please try again.');
            }
        } else if (step === 2) {
            // Verify OTP when the user is on step 2
            await handleVerifyOtp();
        }
    };

    const handleVerifyOtp = async () => {
        if (!supplierId) {
            setErrorMessage('Supplier ID is missing. Please register first.');
            return;
        }

        const formData = new FormData();
        formData.append('mobile_number', phoneNumber);
        formData.append('supplier_id', supplierId); // Ensure supplier_id is sent in the OTP verification request
        formData.append('otp', otp);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/verifyOtp`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.status === "true") {
                // Assuming the data contains supplier information in data.data[0]
                // const supplierInfo = data.data[0];

                // // Save supplier info in localStorage
                // localStorage.setItem('user', JSON.stringify({
                //     phone: phoneNumber,
                //     supplier_id: supplierInfo.supplier_id,
                //     name: supplierInfo.name,
                //     mobile_number: supplierInfo.mobile_number,
                //     shop_name: supplierInfo.shop_name,
                //     whatsapp_number: supplierInfo.whatsapp_number,
                //     email: supplierInfo.email,
                //     link: supplierInfo.supplier_link,
                //     // Add any other supplier information you need
                // }));

                // // Optionally, store supplier_id separately in localStorage
                // localStorage.setItem('supplier_id', supplierInfo.supplier_id);

                // // Update context if necessary
                // setSupplierId(supplierInfo.supplier_id);
                setShopName(data.data[0].shop_name);
                setNumber(data.data[0].mobile_number);
                setOrderLink(data.data[0].supplier_link);
                setIsAuthenticated(true);
                alert('OTP verified successfully!');

                setIsAuthenticated(true);
                navigate('/dashboard'); // Redirect to dashboard after successful OTP verification
            } else {
                setErrorMessage(data.message || 'OTP verification failed.');
            }
        } catch (error) {
            setErrorMessage('An error occurred while verifying OTP. Please try again later.');
            console.error('Error verifying OTP:', error);
        }
    };

    const togglePasswordVisible = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
        if (e.target.value === '') {
            setErrorMessage(''); // Clear error if OTP field is cleared
        }
    };

    const handleResendOtp = async () => {
        setOtp(''); // Clear OTP field
        setErrorMessage(''); // Clear error message
        setTimer(120); // Reset the timer to 120 seconds
        setOtpSent(false); // Reset otpSent to false to trigger effect

        try {
            const otpSentSuccessfully = await handleSendOtp();
            if (otpSentSuccessfully) {
                setOtpSent(true); // Mark OTP as sent if the API call was successful
            } else {
                setOtpSent(false); // If there was an error, set the flag to false
            }
        } catch (error) {
            setErrorMessage('An error occurred while resending OTP. Please try again later.');
            console.error('Error resending OTP:', error);
        }

        alert('OTP sent to your device!');
    };

    // Format the timer in mm:ss format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <div className="register-container">
            <div className="left-section">
                <div className="app-logo">
                    <img src="/images/app_icon.png" alt="App Logo" />
                </div>
                <p>Please Click Here to Download the ListApp</p>
                <button className="download-button">
                    <a href="https://play.google.com/store/apps/details?id=com.listapp.in&hl=en_IN">
                        <img src="/images/pngegg.png" alt="Google Play" />
                    </a>
                </button>
            </div>
            <div className="right-section">
                <div className="form-container">
                    <h2 style={{ textAlign: 'center' }}>Superlist Registration</h2>
                    <div className="back">
                        <div className="form-logo">
                            <img src="/images/app_icon.png" alt="Form Logo" />
                        </div>
                        <form onSubmit={handleNext} className="register-form">
                            {step === 1 && (
                                <>
                                    <label>
                                        Business Name *
                                        <input
                                            type="text"
                                            placeholder="Business Name"
                                            value={businessName}
                                            onChange={(e) => setBusinessName(e.target.value)}
                                            required
                                        />
                                    </label>
                                    <label>
                                        Phone Number *
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            required
                                        />
                                    </label>
                                    <label>
                                        Create Password *
                                        <div className="password-input-container">
                                            <input
                                                type={passwordVisible ? 'text' : 'password'}
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <span
                                                className="password-toggle-icon"
                                                onClick={togglePasswordVisible}
                                            >
                                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </span>
                                        </div>
                                    </label>
                                    <button type="submit" className="sign-up-button">
                                        Next
                                    </button>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <button
                                        type="button"
                                        onClick={handleResendOtp}
                                        style={{
                                            color: 'white',
                                            cursor: 'pointer',
                                            width: '42%',
                                            backgroundColor: 'green',
                                            outline: 'none',
                                            border: 'none',
                                            padding: '6px 10px',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        Get OTP
                                    </button>
                                    <label>
                                        Enter OTP *
                                        <input
                                            type="text"
                                            placeholder="Enter OTP"
                                            value={otp}
                                            onChange={handleOtpChange}
                                            required
                                        />
                                    </label>
                                    <button type="submit" className="sign-up-button" disabled={timer === 0}>
                                        Submit
                                    </button>
                                </>
                            )}
                        </form>
                        <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
