import React, { useState, useRef, useEffect } from 'react';
import { CgMenu, CgProfile } from "react-icons/cg"; // Added CgProfile
import './Dashboard.css';
import OwnerProfile from '../DashPages/OwnerProfile';

function Navbar({ toggleSidebar }) {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const profileIconRef = useRef(null);

  const toggleProfileMenu = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current && !profileMenuRef.current.contains(event.target) &&
        !profileIconRef.current.contains(event.target)
      ) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="top-nav">
      <CgMenu
        style={{
          fontSize: '2rem',
          color: '#fff',
          marginRight: '15px',
          cursor: 'pointer',
          transition: 'color 0.3s ease',
        }}
        onClick={toggleSidebar} // Trigger sidebar toggle
      />
      <h2>Welcome John Doe</h2>
      <div className="nav-icons" ref={profileIconRef}>
        <CgProfile
          style={{
            fontSize: '2rem',
            color: '#fff',
            cursor: 'pointer',
          }}
          onClick={toggleProfileMenu}
        />
      </div>
      {isProfileOpen && (
        <div className="profile-menu" ref={profileMenuRef}>
          <OwnerProfile />
        </div>
      )}
    </div>
  );
}

export default Navbar;
