// old dashboard
// import React from 'react';
// // import StatsCard from './StatsCard'; // Assuming StatsCard is already created

// import './Dashboard.css';
// import ChartCard from '../DashPages/ChartCard';
// import BarChart from '../DashPages/BarChart';
// import StatsCard from './StatsCard';
// import { useNavigate } from 'react-router-dom';

// function Dashboard() {
//   // Example data for the sales chart (hardcoded)

//   const navigate = useNavigate();
//   const salesData = {
//     labels: ['January', 'February', 'March', 'April', 'May', 'June'],
//     datasets: [
//       {
//         label: 'Sales ($)',
//         data: [1200, 1500, 1800, 2200, 2500, 2800], // Hardcoded sales values
//         fill: false,
//         borderColor: 'rgba(75, 192, 192, 1)',
//         tension: 0.1,
//       },
//     ],
//   };

//   // Example data for the users chart (hardcoded)
//   const usersData = {
//     labels: ['January', 'February', 'March', 'April', 'May', 'June'],
//     datasets: [
//       {
//         label: 'New Users',
//         data: [30, 40, 50, 60, 70, 80], // Hardcoded user values
//         fill: false,
//         backgroundColor: 'rgba(53, 162, 235, 0.6)', // Bar color (blue)
//         borderColor: 'rgba(53, 162, 235, 1)', // Border color for each bar
//         // borderColor: 'rgba(153, 102, 255, 1)',
//         tension: 0.1,
//       },
//     ],
//   };

//   const handleRowClick = (productId) => {
//     // Redirect to product page with productId as a parameter
//     navigate(`/product/${productId}`);
//   };

//   return (
//     <div className="dashboard-main-content">
//       <div style={{ padding: '20px' }}>
//         <StatsCard />
//         <div className="charts-section">
//           <ChartCard title="Users Visted" data={salesData} />
//           <div className="top-selling-products">
//             <h3>Top Selling Products</h3>
//             <table>
//               <thead>
//                 <tr>
//                   <th>Product</th>
//                   <th>Company Name</th>
//                   <th>Orders</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {Array.from({ length: 10 }).map((_, index) => (
//                   <tr key={index} onClick={() => handleRowClick(index + 1)} style={{ cursor: 'pointer' }}>
//                     <td>Product {index + 1}</td>
//                     <td>Company {index + 1}</td>
//                     <td>100</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//         <BarChart title="Users" data={usersData} />
//       </div>
//     </div>
//   );
// }

// export default Dashboard;

// new dashboard
// import React, { useContext, useState } from "react";
// import QRCode from "qrcode";
// import "./Dash.css";
// import { SupplierProductContext } from "../../../context/SupplierProductContext";

// function Dashboard() {
//   const { shopName,supplierId, supplierProducts, loading, error } = useContext(SupplierProductContext);
//   const [orderLink, setOrderLink] = useState("");
//   const [generatedImage, setGeneratedImage] = useState(null);
//   const phoneNumber = "7987202207"; // Replace with the supplier's phone number
//   // const shopName = "SuperList"; // Replace with the shop name
//   // const supplierId = "2405"; // Replace with the supplier ID

//   console.log('ww',shopName)
//   console.log('ww',supplierId)
//   const generateQRCode = async (orderLink) => {
//     try {
//       const qrCodeData = await QRCode.toDataURL(orderLink);
//       return qrCodeData;
//     } catch (error) {
//       console.error("Error generating QR code:", error);
//     }
//   };

//   const handleShare = async () => {
//     const orderLink = `https://www.listapp.in/medicine?supplierList=eNortjKxUjIyMTBVsgZcMA_gAmY.&supplierName=SUPERLIST`; // Replace with your actual link
//     setOrderLink(orderLink);

//     // Generate the QR code and prepare canvas for the image
//     const qrCodeData = await generateQRCode(orderLink);

//     if (!qrCodeData) {
//       console.error("QR Code generation failed!");
//       return;
//     }

//     // Prepare canvas and generate the image
//     const canvas = document.createElement("canvas");
//     const context = canvas.getContext("2d");

//     const backgroundUrl = "/images/QR_background.jpeg"; // Replace with the correct background image path
//     const logoUrl = "/images/app_icon.png"; // Replace with your logo path

//     const backgroundImage = new Image();
//     backgroundImage.src = backgroundUrl;

//     backgroundImage.onload = () => {
//       canvas.width = backgroundImage.width;
//       canvas.height = backgroundImage.height;

//       context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

//       // Draw QR code in the center
//       const qrCodeSize = 550;
//       const qrCodeX = (canvas.width - qrCodeSize) / 2;
//       const qrCodeY = (canvas.height - qrCodeSize) / 2 - 60;
//       const qrCodeImg = new Image();
//       qrCodeImg.src = qrCodeData;

//       qrCodeImg.onload = () => {
//         context.drawImage(qrCodeImg, qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

//         // Draw the logo in the center of the QR code
//         const logoSize = qrCodeSize / 5;
//         const logoX = qrCodeX + (qrCodeSize - logoSize) / 2;
//         const logoY = qrCodeY + (qrCodeSize - logoSize) / 2;

//         const logoImg = new Image();
//         logoImg.src = logoUrl;

//         logoImg.onload = () => {
//           context.fillStyle = "white";
//           context.fillRect(logoX, logoY, logoSize, logoSize);
//           context.drawImage(logoImg, logoX, logoY, logoSize, logoSize);

//           // Add the shop name above the QR code
//           context.fillStyle = "white";
//           context.font = "bold 57px Arial";
//           context.textAlign = "center";
//           const maxWidth = canvas.width - 180;
//           const wrapShopName = (text, x, y) => {
//             const words = text.split(" ");
//             let line = "";
//             const lineHeight = 70;
//             for (let n = 0; n < words.length; n++) {
//               const testLine = line + words[n] + " ";
//               const metrics = context.measureText(testLine);
//               const testWidth = metrics.width;
//               if (testWidth > maxWidth && n > 0) {
//                 context.fillText(line, x, y);
//                 line = words[n] + " ";
//                 y += lineHeight;
//               } else {
//                 line = testLine;
//               }
//             }
//             context.fillText(line, x, y);
//           };
//           wrapShopName(shopName, canvas.width / 2, 180);

//           // Add phone number at the bottom
//           context.fillStyle = "white";
//           context.font = "bold 60px Arial";
//           context.textAlign = "center";
//           context.fillText(phoneNumber, canvas.width / 2, canvas.height - 410);

//           // Convert canvas to blob
//           canvas.toBlob(async (blob) => {
//             if (blob) {
//               const finalQRCodeFile = new File([blob], "qrcode.png", { type: "image/png" });

//               // Now set the generated image to state to show it to the user
//               const imageUrl = URL.createObjectURL(blob);
//               setGeneratedImage(imageUrl); // Show the generated QR code to the user

//               // Now you can share the image using Web Share API
//               const shareContent = {
//                 title: "Order Link -",
//                 text: `Order Link - ${orderLink}`,
//                 url: orderLink,
//                 files: [finalQRCodeFile], // Sharing the file
//               };

//               // Check if the Web Share API is supported
//               if (navigator.share) {
//                 try {
//                   await navigator.share(shareContent);
//                   console.log("Content shared successfully!");
//                 } catch (error) {
//                   console.error("Error sharing content:", error);
//                 }
//               } else {
//                 console.warn("Web Share API not supported.");
//               }
//             } else {
//               console.error("Error creating blob from canvas.");
//             }
//           }, "image/png");
//         };
//       };
//     };
//   };

//   const handleWatsappShare = () => {
//     const shareContent = `Hello, I want to know about ${shopName}.`;
//     const whatsappLink = `https://api.whatsapp.com/send?phone=91${phoneNumber}&text=${encodeURIComponent(shareContent)}`;
//     window.open(whatsappLink, "_blank");
//   };

//   const handleDownloadImage = () => {
//     if (!generatedImage) return;
//     const a = document.createElement("a");
//     a.href = generatedImage;
//     a.download = `${shopName}_QRCode.png`;
//     a.click();
//   };


//   return (
//     <div className="dashboard-main-content">
//       <h1 style={{ textAlign: "center" }}>Welcome to {shopName}'s Dashboard</h1>
//       <div className="dashboard-section">
//         <div className="button-container">
//           <button className="dashbutton" onClick={handleShare}>Generate QR Code & Share</button>
//           <button className="dashbutton" onClick={handleWatsappShare}>Watsapp Share</button>
//         </div>

//         {/* Show the generated image */}
//         {generatedImage && (
//           <div className="generated-image">
//             <h3>Generated QR Code Image</h3>
//             <img
//               src={generatedImage}
//               alt="Generated QR Code"
//               className="generated-image-preview"
//             />
//             <p>
//               Order Link:{" "}
//               <a href={orderLink} target="_blank" rel="noopener noreferrer">
//                 {orderLink}
//               </a>
//             </p>
//             <button className="dashbutton" onClick={handleDownloadImage}>Download QR Code</button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Dashboard;

// new code
import React, { useContext, useState, useEffect } from "react";
import QRCode from "qrcode";
import "./Dash.css";
import { SupplierProductContext } from "../../../context/SupplierProductContext";

function Dashboard() {
  const { shopName, supplierId,number, orderLink, supplierProducts, loading, error } = useContext(SupplierProductContext);

  const [generatedImage, setGeneratedImage] = useState(null);
  const [supplierDetails, setSupplierDetails] = useState(null); // State to store supplier details
  const [loadingDetails, setLoadingDetails] = useState(true); // To track if data is being fetched
  const [fetchError, setFetchError] = useState(null); // To track if there's an error fetching data

  console.log('ww', shopName)
  console.log('si', supplierId)
  console.log('nu', number)
  console.log('ooo', orderLink)
  // Fetch supplier details from the API on component mount
  useEffect(() => {
    const fetchSupplierDetails = async () => {
      try {
        // Get mobile number and supplier ID from local storage or context
        // const mobileNumber = localStorage.getItem("mobile_number") || number;
        const supplierIdFromContext = supplierId;

        // Create FormData object and append data
        const formData = new FormData();
        // formData.append('mobile_number', mobileNumber);
        formData.append('supplier_id', supplierIdFromContext);

        // Call the API to fetch supplier details using the fetch method
        const response = await fetch('https://orderlist.in/superlist/supplier/getSupplierDetail', {
          method: 'POST',
          body: formData, // Send the form data as the request body
        });

        if (response.ok) {
          const data = await response.json(); // Parse the JSON response
          if (data) {
            // Update state with the fetched details
            setSupplierDetails(data);
          } else {
            setFetchError('Failed to fetch supplier details');
          }
        } else {
          setFetchError('Failed to fetch supplier details');
        }
      } catch (error) {
        setFetchError('Error fetching supplier details');
        console.error("API Error:", error);
      } finally {
        setLoadingDetails(false);
      }
    };
    console.log("calling fetch - "+shopName+" "+supplierId);

    fetchSupplierDetails();
  }, [supplierId, number]);

  const generateQRCode = async (orderLink) => {
    try {
      const qrCodeData = await QRCode.toDataURL(orderLink);
      return qrCodeData;
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const handleShare = async () => {
    const orderLink = `https://www.listapp.in/medicine?supplierList=ePoerjKxUjIyMTBVsgZcMA_gAmY.&supplierName=SUPERLIST`;

    const qrCodeData = await generateQRCode(orderLink);

    if (!qrCodeData) {
      console.error("QR Code generation failed!");
      return;
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const backgroundUrl = "/images/QR_background.jpeg";
    const logoUrl = "/images/app_icon.png";

    const backgroundImage = new Image();
    backgroundImage.src = backgroundUrl;

    backgroundImage.onload = () => {
      canvas.width = backgroundImage.width;
      canvas.height = backgroundImage.height;

      context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

      const qrCodeSize = 550;
      const qrCodeX = (canvas.width - qrCodeSize) / 2;
      const qrCodeY = (canvas.height - qrCodeSize) / 2 - 60;
      const qrCodeImg = new Image();
      qrCodeImg.src = qrCodeData;

      qrCodeImg.onload = () => {
        context.drawImage(qrCodeImg, qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);

        const logoSize = qrCodeSize / 5;
        const logoX = qrCodeX + (qrCodeSize - logoSize) / 2;
        const logoY = qrCodeY + (qrCodeSize - logoSize) / 2;

        const logoImg = new Image();
        logoImg.src = logoUrl;

        logoImg.onload = () => {
          context.fillStyle = "white";
          context.fillRect(logoX, logoY, logoSize, logoSize);
          context.drawImage(logoImg, logoX, logoY, logoSize, logoSize);

          context.fillStyle = "white";
          context.font = "bold 57px Arial";
          context.textAlign = "center";
          const maxWidth = canvas.width - 180;
          const wrapShopName = (text, x, y) => {
            const words = text.split(" ");
            let line = "";
            const lineHeight = 70;
            for (let n = 0; n < words.length; n++) {
              const testLine = line + words[n] + " ";
              const metrics = context.measureText(testLine);
              const testWidth = metrics.width;
              if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + " ";
                y += lineHeight;
              } else {
                line = testLine;
              }
            }
            context.fillText(line, x, y);
          };
          wrapShopName(shopName, canvas.width / 2, 180);

          context.fillStyle = "white";
          context.font = "bold 60px Arial";
          context.textAlign = "center";
          context.fillText(number, canvas.width / 2, canvas.height - 410);

          canvas.toBlob(async (blob) => {
            if (blob) {
              const finalQRCodeFile = new File([blob], "qrcode.png", { type: "image/png" });
              const imageUrl = URL.createObjectURL(blob);
              setGeneratedImage(imageUrl);

              const shareContent = {
                title: "Order Link -",
                text: `Order Link - ${orderLink}`,
                url: orderLink,
                files: [finalQRCodeFile],
              };

              if (navigator.share) {
                try {
                  await navigator.share(shareContent);
                  console.log("Content shared successfully!");
                } catch (error) {
                  console.error("Error sharing content:", error);
                }
              } else {
                console.warn("Web Share API not supported.");
              }
            } else {
              console.error("Error creating blob from canvas.");
            }
          }, "image/png");
        };
      };
    };
  };

  const handleWatsappShare = () => {
    const shareContent = `Hello, I want to know about ${shopName}.`;
    const whatsappLink = `https://api.whatsapp.com/send?phone=91${number}&text=${encodeURIComponent(shareContent)}`;
    window.open(whatsappLink, "_blank");
  };

  const handleDownloadImage = () => {
    if (!generatedImage) return;
    const a = document.createElement("a");
    a.href = generatedImage;
    a.download = `${shopName}_QRCode.png`;
    a.click();
  };

  return (
    <div className="dashboard-main-content">
      <h1 style={{ textAlign: "center" }}>Welcome to {shopName}'s Dashboard</h1>

      {/* Show loading spinner or message */}
      {loadingDetails && <p>Loading supplier details...</p>}
      {fetchError && <p style={{ color: 'red' }}>{fetchError}</p>}

      <div className="dashboard-section">
        <div className="button-container">
          <button className="dashbutton" onClick={handleShare}>Generate QR Code & Share</button>
          <button className="dashbutton" onClick={handleWatsappShare}>Watsapp Share</button>
        </div>

        {generatedImage && (
          <div className="generated-image">
            <h3>Generated QR Code Image</h3>
            <img src={generatedImage} alt="Generated QR Code" className="generated-image-preview" />
            <p>Order Link: <a href={orderLink} target="_blank" rel="noopener noreferrer">{orderLink}</a></p>
            <button className="dashbutton" onClick={handleDownloadImage}>Download QR Code</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
