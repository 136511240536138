// import React, { useContext, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { FaTachometerAlt } from 'react-icons/fa';
// import { FiLoader } from 'react-icons/fi'; // Importing a spinner icon
// import "../ProductPage/EditProduct.css";
// import { FaSync } from "react-icons/fa";
// import { SupplierProductContext } from '../../../../context/SupplierProductContext';

// const BulkUploadProduct = () => {
//   const { supplierId, sheetLink, supplierProducts, loading, fetchSupplierProducts } = useContext(SupplierProductContext);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [file, setFile] = useState(null); // For bulk XLSX file
//   // const [googleSheetLink, setGoogleSheetLink] = useState(''); // Google Sheet link
//   // const [googleSheetLink, setGoogleSheetLink] = useState('https://docs.google.com/spreadsheets/d/1B2D3j4K5l6J7O8P9Q0R1S2T3u4V5W6X7YZ8A9BcD0E/edit?usp=sharing'); // Hardcoded Google Sheet link
//   const [googleSheetLink, setGoogleSheetLink] = useState(''); // Hardcoded Google Sheet link
//   const [uploadMethod, setUploadMethod] = useState('excel'); // Toggle between excel and google sheet
//   const [uploadAction, setUploadAction] = useState('replace'); // Action for upload: "replace" or "append"
//   const [isExcelUploading, setIsExcelUploading] = useState(false); // Loading state for Excel
//   const [isGoogleSheetUploading, setIsGoogleSheetUploading] = useState(false); // Loading state for Google Sheet

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleUploadActionChange = (action) => {
//     setUploadAction(action);
//   };

//   const handleUpload = async () => {
//     if (uploadMethod === 'excel' && !file) {
//       alert("Please select an Excel file to upload.");
//       return;
//     }

//     if (uploadMethod === 'googleSheet' && !googleSheetLink) {
//       alert("Please enter a valid Google Sheet link.");
//       return;
//     }

//     if (uploadMethod === 'excel') {
//       setIsExcelUploading(true);
//     } else if (uploadMethod === 'googleSheet') {
//       setIsGoogleSheetUploading(true);
//     }

//     const formData = new FormData();
//     formData.append("supplier_id", supplierId);
//     formData.append("input_method", uploadAction);

//     if (uploadMethod === 'excel' && file) {
//       formData.append("excel_file", file, file.name);
//     }

//     if (uploadMethod === 'googleSheet' && googleSheetLink) {
//       formData.append("google_sheet_link", googleSheetLink);
//     }

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/addBulkProduct`, {
//         method: 'POST',
//         body: formData,
//       });

//       const result = await response.json();
//       if (result.status) {
//         alert("Bulk upload successful!");
//         navigate("/products");
//       } else {
//         alert(result.message || "Failed to upload.");
//       }
//     } catch (error) {
//       console.error("Error uploading:", error);
//     } finally {
//       setIsExcelUploading(false);
//       setIsGoogleSheetUploading(false);
//     }
//   };

//   return (
//     <div>
//       <div className="breadcrumb-bar">
//         <FaTachometerAlt />
//         <span onClick={() => navigate('/dashboard', { state: { from: location } })}>
//           Dashboard
//         </span>
//         <span>/</span>
//         <span onClick={() => navigate('/products', { state: { from: location } })}>
//           Products
//         </span>
//         <span>/</span>
//         <span style={{ textDecoration: 'none' }}>Bulk Upload</span>
//       </div>

//       <div className="bulk-upload-container">
//         <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
//           <h2>Bulk Upload</h2>
//           <a
//             href="assets/Sample Superlist File.xlsx"
//             download="Sample Superlist File.xlsx"
//             className="btn-download"
//           >
//             Download Sample File
//           </a>
//         </div>

//         <div className="upload-method-toggle" style={{ marginBottom: "1rem" }}>
//           <div className={`toggle-button ${uploadMethod === 'excel' ? 'active' : ''}`} onClick={() => setUploadMethod('excel')}>
//             Upload Excel File
//           </div>
//           <div className={`toggle-button ${uploadMethod === 'googleSheet' ? 'active' : ''}`} onClick={() => setUploadMethod('googleSheet')}>
//             Upload Google Sheet
//           </div>
//         </div>

//         {uploadMethod === 'excel' && (
//           <div className="form-group">
//             <div className="form-group">
//               <label>Choose Action</label>
//               <div>
//                 <label style={{ cursor: 'pointer' }}>
//                   <input
//                     type="radio"
//                     name="uploadAction"
//                     value="append"
//                     checked={uploadAction === 'append'}
//                     onChange={() => handleUploadActionChange('append')}
//                     style={{ cursor: 'pointer' }}
//                   />
//                   Append to Existing Data
//                 </label>
//                 <label style={{ cursor: 'pointer' }}>
//                   <input
//                     type="radio"
//                     name="uploadAction"
//                     value="replace"
//                     checked={uploadAction === 'replace'}
//                     onChange={() => handleUploadActionChange('replace')}
//                     style={{ cursor: 'pointer' }}
//                   />
//                   Replace Existing Data
//                 </label>
//               </div>
//             </div>
//             <label htmlFor="bulkFile">Upload XLSX File*</label>
//             <input type="file" accept=".xlsx" id="bulkFile" onChange={handleFileChange} />
//             <p>Note: Please use the sample XLSX file or XLS file for bulk product upload.</p>
//           </div>
//         )}

//         {uploadMethod === 'googleSheet' && (
//           <div className="form-group">
//             <label htmlFor="googleSheetLink">Google Sheet Link*</label>
//             <input
//               type="url"
//               id="googleSheetLink"
//               placeholder="Enter Google Sheet link"
//               // value={googleSheetLink}
//               value={sheetLink}
//               // onChange={(e) => setGoogleSheetLink(e.target.value)}
//               style={{
//                 // width: "60%",
//                 width: "72%",
//                 padding: "10px",
//                 marginBottom: "20px",
//                 fontSize: "16px",
//                 border: "1px solid #ddd",
//                 borderRadius: "8px",
//                 boxSizing: "border-box",
//                 backgroundColor: "#f9f9f9", // Light grey to indicate read-only
//                 cursor: "not-allowed", // Pointer to indicate it's not editable
//               }}
//             />

//             <div style={{ marginTop: "10px" }}>
//               <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
//                 <button
//                   onClick={async () => {
//                     const formData = new FormData();
//                     formData.append('supplier_id', supplierId);
//                     formData.append('sheet_link', sheetLink);              
//                     try {
//                       // Perform your sync action here, e.g., fetch API
//                       const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/syncSheet`, {
//                         method: "POST",
//                         body: formData,
//                       });
//                       const result = await response.json();

//                       if (result.status) {
//                         alert("Google Sheet synced successfully!");
//                         // Optionally update state or UI based on sync result
//                       } else {
//                         alert(result.message || "Failed to sync Google Sheet.");
//                       }
//                     } catch (error) {
//                       console.error("Error syncing Google Sheet:", error);
//                       alert("An error occurred while syncing. Please try again.");
//                     }
//                   }}
//                   style={{
//                     background: "#007bff",
//                     color: "#fff",
//                     border: "none",
//                     borderRadius: "4px",
//                     padding: "8px 12px",
//                     cursor: "pointer",
//                   }}
//                 >
//                   <FaSync /> Sync
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}

//         {uploadMethod === 'excel' && (
//           <button
//             onClick={handleUpload}
//             className="btn-upload"
//             disabled={isExcelUploading}
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             {isExcelUploading ? (
//               <>
//                 <FiLoader className="spinner" style={{ width: '30px', height: '20px', marginRight: "10px", animation: "spin 1s linear infinite" }} />
//                 Uploading Excel...
//               </>
//             ) : (
//               "Upload Excel"
//             )}
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default BulkUploadProduct;

import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTachometerAlt } from 'react-icons/fa';
import { FiLoader } from 'react-icons/fi'; // Importing a spinner icon
import "../ProductPage/EditProduct.css";
import { FaSync } from "react-icons/fa";
import { SupplierProductContext } from '../../../../context/SupplierProductContext';

const BulkUploadProduct = () => {
  const { supplierId, sheetLink, supplierProducts, loading, fetchSupplierProducts } = useContext(SupplierProductContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile] = useState(null); // For bulk XLSX file
  const [googleSheetLink, setGoogleSheetLink] = useState(''); // Google Sheet link
  const [uploadMethod, setUploadMethod] = useState('excel'); // Toggle between excel and google sheet
  const [uploadAction, setUploadAction] = useState('replace'); // Action for upload: "replace" or "append"
  const [isExcelUploading, setIsExcelUploading] = useState(false); // Loading state for Excel
  const [isGoogleSheetUploading, setIsGoogleSheetUploading] = useState(false); // Loading state for Google Sheet
  const [isSyncing, setIsSyncing] = useState(false); // Syncing state for Google Sheet sync

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadActionChange = (action) => {
    setUploadAction(action);
  };

  const handleUpload = async () => {
    if (uploadMethod === 'excel' && !file) {
      alert("Please select an Excel file to upload.");
      return;
    }

    if (uploadMethod === 'googleSheet' && !googleSheetLink) {
      alert("Please enter a valid Google Sheet link.");
      return;
    }

    if (uploadMethod === 'excel') {
      setIsExcelUploading(true);
    } else if (uploadMethod === 'googleSheet') {
      setIsGoogleSheetUploading(true);
    }

    const formData = new FormData();
    formData.append("supplier_id", supplierId);
    formData.append("input_method", uploadAction);

    if (uploadMethod === 'excel' && file) {
      formData.append("excel_file", file, file.name);
    }

    if (uploadMethod === 'googleSheet' && googleSheetLink) {
      formData.append("google_sheet_link", googleSheetLink);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/addBulkProduct`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.status) {
        alert("Bulk upload successful!");
        navigate("/products");
      } else {
        alert(result.message || "Failed to upload.");
      }
    } catch (error) {
      console.error("Error uploading:", error);
    } finally {
      setIsExcelUploading(false);
      setIsGoogleSheetUploading(false);
    }
  };

  // Sync Google Sheet
  const handleSync = async () => {
    setIsSyncing(true); // Start syncing

    const formData = new FormData();
    formData.append('supplier_id', supplierId);
    formData.append('sheet_link', sheetLink);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/syncSheet`, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();

      if (result.status) {
        alert("Google Sheet synced successfully!");
      } else {
        alert(result.message || "Failed to sync Google Sheet.");
      }
    } catch (error) {
      console.error("Error syncing Google Sheet:", error);
      alert("An error occurred while syncing. Please try again.");
    } finally {
      setIsSyncing(false); // Stop syncing
    }
  };

  return (
    <div>
      <div className="breadcrumb-bar">
        <FaTachometerAlt />
        <span onClick={() => navigate('/dashboard', { state: { from: location } })}>
          Dashboard
        </span>
        <span>/</span>
        <span onClick={() => navigate('/products', { state: { from: location } })}>
          Products
        </span>
        <span>/</span>
        <span style={{ textDecoration: 'none' }}>Bulk Upload</span>
      </div>

      <div className="bulk-upload-container">
        <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
          <h2>Bulk Upload</h2>
          <a
            href="assets/Sample Superlist File.xlsx"
            download="Sample Superlist File.xlsx"
            className="btn-download"
          >
            Download Sample File
          </a>
        </div>

        <div className="upload-method-toggle" style={{ marginBottom: "1rem" }}>
          <div className={`toggle-button ${uploadMethod === 'excel' ? 'active' : ''}`} onClick={() => setUploadMethod('excel')}>
            Upload Excel File
          </div>
          <div className={`toggle-button ${uploadMethod === 'googleSheet' ? 'active' : ''}`} onClick={() => setUploadMethod('googleSheet')}>
            Upload Google Sheet
          </div>
        </div>

        {uploadMethod === 'excel' && (
          <div className="form-group">
            <div className="form-group">
              <label>Choose Action</label>
              <div>
                <label style={{ cursor: 'pointer' }}>
                  <input
                    type="radio"
                    name="uploadAction"
                    value="append"
                    checked={uploadAction === 'append'}
                    onChange={() => handleUploadActionChange('append')}
                    style={{ cursor: 'pointer' }}
                  />
                  Append to Existing Data
                </label>
                <label style={{ cursor: 'pointer' }}>
                  <input
                    type="radio"
                    name="uploadAction"
                    value="replace"
                    checked={uploadAction === 'replace'}
                    onChange={() => handleUploadActionChange('replace')}
                    style={{ cursor: 'pointer' }}
                  />
                  Replace Existing Data
                </label>
              </div>
            </div>
            <label htmlFor="bulkFile">Upload XLSX File*</label>
            <input type="file" accept=".xlsx" id="bulkFile" onChange={handleFileChange} />
            <p>Note: Please use the sample XLSX file or XLS file for bulk product upload.</p>
          </div>
        )}

        {uploadMethod === 'googleSheet' && (
          <div className="form-group">
            <label htmlFor="googleSheetLink">Google Sheet Link*</label>
            <input
              type="url"
              id="googleSheetLink"
              placeholder="Enter Google Sheet link"
              value={sheetLink}
              style={{
                width: "72%",
                padding: "10px",
                marginBottom: "20px",
                fontSize: "16px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxSizing: "border-box",
                backgroundColor: "#f9f9f9", // Light grey to indicate read-only
                cursor: "not-allowed", // Pointer to indicate it's not editable
              }}
            />

            <div style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <button
                  onClick={handleSync}
                  style={{
                    display:'flex',
                    alignItems:'center',
                    background: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    padding: "8px 12px",
                    cursor: "pointer",
                  }}
                >
                  <FaSync
                    style={{
                      animation: isSyncing ? 'spin 1s linear infinite' : 'none',
                      width: '20px',
                      height: '20px',
                      marginRight: '5px',
                    }}
                  />
                  {isSyncing ? 'Syncing...' : 'Sync'}
                </button>
              </div>
            </div>
          </div>
        )}

        {uploadMethod === 'excel' && (
          <button
            onClick={handleUpload}
            className="btn-upload"
            disabled={isExcelUploading}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isExcelUploading ? (
              <>
                <FiLoader className="spinner" style={{ width: '30px', height: '20px', marginRight: "10px", animation: "spin 1s linear infinite" }} />
                Uploading Excel...
              </>
            ) : (
              "Upload Excel"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default BulkUploadProduct;
