import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Login.css';
import { SupplierProductContext } from '../../../context/SupplierProductContext';

function Login({ setIsAuthenticated }) {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1); // Step 1: Phone Verification, Step 2: Password Verification
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const [supplierId, setNewSupplier] = useState(''); // State to store supplierId

  const { setSupplierId } = useContext(SupplierProductContext);
  const { setShopName } = useContext(SupplierProductContext);
  const { setNumber } = useContext(SupplierProductContext);
  const { setOrderLink } = useContext(SupplierProductContext);
  const { setSheetLink } = useContext(SupplierProductContext);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setPhone(storedUser.phone); // Automatically set the phone number from localStorage
    }
  }, []);

  const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber.length !== 10) {
      return "Phone number must be exactly 10 digits.";
    }
    if (['1', '2', '3', '4', '5'].includes(phoneNumber[0])) {
      return "Phone number cannot start with 1, 2, 3, 4, or 5.";
    }
    if (/[^0-9]/.test(phoneNumber)) {
      return "Phone number can only contain numbers.";
    }
    if (/[a-zA-Z]/.test(phoneNumber)) {
      return "Phone number cannot contain letters.";
    }
    return ''; // If all validations pass
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();

    const validationError = validatePhoneNumber(phone);
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    const formData = new FormData();
    formData.append('mobile_number', phone);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/checkDeviceReg`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data?.status === "true") {
        // If status is true, extract supplier_id and move to password verification step
        const supplier_id = data.data[0].supplier_id;
        setNewSupplier(supplier_id);
        setSupplierId(supplier_id); // Save the supplier_id to state
        localStorage.setItem('supplier_id', supplier_id); // Save supplier_id to localStorage
        setStep(2); // Move to password verification step
        setErrorMessage(''); // Clear any previous error message
      } else {
        alert('No account found. Redirecting to registration.');
        navigate('/register', { state: { phoneNumber: phone } }); // Pass phone number to Register component
      }
    } catch (error) {
      console.error('Error validating phone number:', error);
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (!supplierId) {
      setErrorMessage('Supplier ID is missing. Please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('supplier_id', supplierId);
    formData.append('mobile_number', phone);
    formData.append('password', password);

    try {
      // const response = await fetch('https://orderlist.in/superlist/supplier/passwordVerification', {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/passwordVerification`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data?.status === "true") {
        // Save supplier information to localStorage
        // const supplierInfo = data.data[0];  // Assuming data.data[0] contains the supplier's information
        // localStorage.setItem('user', JSON.stringify({
        //   phone: phone,
        //   supplier_id: supplierId,
        //   name: supplierInfo.name,
        //   mobile_number: supplierInfo.mobile_number,
        //   shop_name: supplierInfo.shop_name,
        //   whatsapp_number: supplierInfo.whatsapp_number,
        //   email: supplierInfo.email,
        //   link: supplierInfo.supplier_link,
        //   // Add any other supplier information you need
        // }));
        // setNewSupplier(data.data[0].supplier_id);
        setShopName(data.data[0].shop_name);
        setNumber(data.data[0].mobile_number);
        setOrderLink(data.data[0].supplier_link);
        setSheetLink(data.data[0].sheet_link); // Set sheetLink here
        setIsAuthenticated(true);
        navigate('/dashboard'); // Redirect to the dashboard after successful login
      } else {
        setErrorMessage('Incorrect password. Please try again.'); // Display error message for incorrect password
      }
    } catch (error) {
      console.error('Error during password verification:', error);
      setErrorMessage('Something went wrong during password verification. Please try again.');
    }
  };

  const togglePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setErrorMessage(''); // Clear error message when user starts typing
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage('');
  };

  return (
    <div className="register-container">
      <div className="left-section">
        <div className="app-logo">
          <img src="/images/app_icon.png" alt="App Logo" />
        </div>
        <p>Please Click Here to Download the ListApp</p>
        <button className="download-button">
          <a href="https://play.google.com/store/apps/details?id=com.listapp.in&hl=en_IN">
            <img src="/images/pngegg.png" alt="Google Play" />
          </a>
        </button>
      </div>
      <div className="right-section">
        <div className="form-container">
          <h2 style={{ textAlign: 'center' }}>Account Login</h2>
          <div className="back">
            <div className="form-logo">
              <img src="/images/app_icon.png" alt="Form Logo" />
            </div>
            {step === 1 ? (
              <form onSubmit={handlePhoneSubmit} className="register-form">
                <label>
                  Enter Phone Number *
                  <input
                    type="text"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={handlePhoneChange} // Updated onChange handler
                    required
                  />
                </label>
                {errorMessage && (
                  <p style={{ color: 'red', fontSize: '0.9em' }}>{errorMessage}</p>
                )}
                <button type="submit" className="sign-up-button">
                  Next
                </button>
              </form>
            ) : (
              <form onSubmit={handlePasswordSubmit} className="register-form">
                <label>
                  Password *
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <input
                      type={passwordVisible ? 'text' : 'password'}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                      style={{ paddingRight: '40px' }}
                    />
                    <button
                      type="button"
                      className="toggle-password-button"
                      onClick={togglePasswordVisible}
                      style={{
                        position: 'absolute',
                        marginTop: '0.5rem',
                        right: '10px',
                        background: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </label>
                {errorMessage && (
                  <p style={{ color: 'red', fontSize: '0.9em' }}>{errorMessage}</p>
                )}
                <button type="submit" className="sign-up-button">
                  Login
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
