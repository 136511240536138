// import React, { useState, useEffect, useRef } from 'react';
// import { Outlet } from 'react-router-dom';
// import Sidebar from './pages/Dashboard/Sidebar';
// import Navbar from './pages/Dashboard/Navbar';
// import './pages/Dashboard/Dashboard.css';
// import Footer from './pages/Dashboard/Footer';

// function Layout() {
//   const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth >= 768); // Default visible above 768px
//   const [isCollapsed, setIsCollapsed] = useState(false); // For desktop collapsible behavior
//   const sidebarRef = useRef(null); // Reference to the sidebar

//   const toggleSidebar = () => {
//     if (window.innerWidth < 768) {
//       setIsSidebarVisible((prev) => !prev); // Toggle sidebar visibility on small screens
//     } else {
//       setIsCollapsed((prev) => !prev); // Collapse/expand sidebar on large screens
//     }
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth < 768) {
//         setIsSidebarVisible(false); // Hide sidebar on smaller screens
//         setIsCollapsed(false); // Disable collapse behavior on small screens
//       } else {
//         setIsSidebarVisible(true); // Ensure sidebar is visible on larger screens
//       }
//     };

//     const handleClickOutside = (event) => {
//       if (
//         sidebarRef.current && // Sidebar exists
//         !sidebarRef.current.contains(event.target) && // Click is outside the sidebar
//         window.innerWidth < 768 // Only hide on smaller screens
//       ) {
//         setIsSidebarVisible(false); // Hide sidebar
//       }
//     };

//     // Add event listeners
//     window.addEventListener('resize', handleResize);
//     document.addEventListener('mousedown', handleClickOutside);

//     // Cleanup listeners on component unmount
//     return () => {
//       window.removeEventListener('resize', handleResize);
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="dashboard-container">
//       {isSidebarVisible && (
//         <Sidebar
//           ref={sidebarRef} // Pass the ref to Sidebar
//           isCollapsed={isCollapsed}
//           isSidebarVisible={isSidebarVisible}
//         />
//       )}
//       <div className="main-content">
//         <Navbar toggleSidebar={toggleSidebar} />
//         <div className="page-content">
//           <Outlet />
//         </div>
//         <Footer/>
//       </div>
//     </div>
//   );
// }

// export default Layout;

import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './pages/Dashboard/Sidebar';
import Navbar from './pages/Dashboard/Navbar';
import './pages/Dashboard/Dashboard.css';
import Footer from './pages/Dashboard/Footer';

function Layout() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth >= 768); // Default visible above 768px
  const [isCollapsed, setIsCollapsed] = useState(false); // For desktop collapsible behavior
  const sidebarRef = useRef(null); // Reference to the sidebar

  // Toggle sidebar visibility for mobile screens and collapse for desktop
  const toggleSidebar = () => {
    if (window.innerWidth < 768) {
      setIsSidebarVisible((prev) => !prev); // Toggle sidebar visibility on small screens
    } else {
      setIsCollapsed((prev) => !prev); // Collapse/expand sidebar on large screens
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarVisible(false); // Hide sidebar on smaller screens
        setIsCollapsed(false); // Disable collapse behavior on small screens
      } else {
        setIsSidebarVisible(true); // Ensure sidebar is visible on larger screens
      }
    };

    const handleClickOutside = (event) => {
      if (
        sidebarRef.current && // Sidebar exists
        !sidebarRef.current.contains(event.target) && // Click is outside the sidebar
        window.innerWidth < 768 // Only hide on smaller screens
      ) {
        setIsSidebarVisible(false); // Hide sidebar
      }
    };

    // Add event listeners
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dashboard-container">
      {isSidebarVisible && (
        <Sidebar
          ref={sidebarRef} // Pass the ref to Sidebar
          isCollapsed={isCollapsed}
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar} // Pass toggleSidebar to Sidebar
        />
      )}
      <div className="main-content">
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="page-content">
          <Outlet />
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default Layout;
