import React from 'react'
import './Dashboard.css';
const Footer = () => {
  return (
    // <div className='footer'>
    <div className='footer-text-wrapper'>
      2024 © ListApp
    </div>
  )
}

export default Footer
