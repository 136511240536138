import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Close icon
import "../ProductPage/ProductsPage.css";

const ProductDetails = ({ product, handleClose }) => {
  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  };

  const modalContentStyle = {
    backgroundColor: "rgb(255, 255, 255)",
    padding: "20px",
    borderRadius: "8px",
    width: "80%",
    maxWidth: "400px",
    marginBottom: "4rem",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 10px",
    overflowY: "auto",
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#333'
  };

  const tableStyle = {
    width: '100%',
    marginTop: '20px',
    borderCollapse: 'collapse'
  };

  const tdStyle = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  };

  const thStyle = {
    textAlign: 'left',
    fontWeight: 'bold',
    color: '#333'
  };

  const statusStyle = {
    color: product.status === 'active' ? 'green' : 'red'
  };

  return (
    <div className="modal-overlay" style={modalOverlayStyle}>
      <div className="modal-content" style={modalContentStyle}>
        <button className="close-btn" onClick={handleClose} style={closeButtonStyle}>
          <FaTimes />
        </button>
        <h2 style={{ textAlign: 'center', color: '#333' }}>Product Details</h2>
        <table className="modal-table" style={tableStyle}>
          <tbody>
            <tr>
              <td style={thStyle}>Product Name</td>
              <td style={tdStyle}>{product.product_name}</td>
            </tr>
            <tr>
              <td style={thStyle}>Scheme</td>
              <td style={tdStyle}>{product.Scheme || "NA"}</td>
            </tr>
            <tr>
              <td style={thStyle}>MRP</td>
              <td style={tdStyle}>{product.mrp}</td>
            </tr>
            <tr>
              <td style={thStyle}>Current Stock</td>
              <td style={tdStyle}>{product.current_stock || "NA"}</td>
            </tr>
            <tr>
              <td style={thStyle}>Company Name</td>
              <td style={tdStyle}>{product.company_name}</td>
            </tr>
            <tr>
              <td style={thStyle}>Drug Name</td>
              <td style={tdStyle}>{product.drug || "NA"}</td>
            </tr>
            <tr>
              <td style={thStyle}>Rate</td>
              <td style={tdStyle}>{product.rate}</td>
            </tr>
            <tr>
              <td style={thStyle}>Form</td>
              <td style={tdStyle}>{product.form || "NA"}</td>
            </tr>
            <tr>
              <td style={thStyle}>Packaging Type</td>
              <td style={tdStyle}>{product.packingtype_name || "NA"}</td>
            </tr>
            <tr>
              <td style={thStyle}>Pack Size</td>
              <td style={tdStyle}>{product.pack_size || "NA"}</td>
            </tr>
            <tr>
              <td style={thStyle}>Status</td>
              <td style={{ ...tdStyle, ...statusStyle }}>
                {product.status === 'active' ? 'Active' : 'Deactive'}
              </td>
            </tr>
            <tr>
              <td style={thStyle}>Created Date</td>
              <td style={tdStyle}>{new Date(product.created_at).toLocaleDateString()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductDetails;
