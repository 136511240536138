import React, { forwardRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaTachometerAlt, FaBell, FaTimes } from 'react-icons/fa';
import { BsBoxSeamFill, BsInfoSquareFill } from 'react-icons/bs';
import { HiUsers } from 'react-icons/hi2';
import { IoMdCheckbox } from "react-icons/io";
import './Dashboard.css';

const Sidebar = forwardRef(({ isCollapsed, isSidebarVisible, toggleSidebar }, ref) => {
  const location = useLocation();

  // Check if the current path matches the products page or any of its sub-routes
  const isProductPageActive = location.pathname.startsWith('/products') ||
    location.pathname === '/add-product' ||
    location.pathname === '/add-bulk-product' ||
    location.pathname === '/edit-product';

  return (
    <div
      ref={ref}
      className={`sidebar ${isCollapsed ? 'collapsed' : ''} ${isSidebarVisible ? 'show' : 'hide'}`}
    >
      <div className="logo">
        <img src="/images/app_icon.png" alt="App Logo" />
        {!isCollapsed && (
          <span>
            <span className="super">Super</span>
            <span className="list">List</span>
          </span>
        )}
      </div>

      {/* Close icon for small screens */}
      {window.innerWidth < 768 && (
        <div className="close-btn" onClick={toggleSidebar}>
          <FaTimes size={18} color="#fff" />
        </div>
      )}

      <ul>
        <li>
          <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'active' : '')}>
            <FaTachometerAlt />
            {!isCollapsed && 'Dashboard'}
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/supplier-info" className={({ isActive }) => (isActive ? 'active' : '')}>
            <BsInfoSquareFill />
            {!isCollapsed && 'Supplier Information'}
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to="/products"
            className={isProductPageActive ? 'active' : ''}
          >
            <BsBoxSeamFill />
            {!isCollapsed && 'Products'}
          </NavLink>
        </li>
        {/* <li>
          <NavLink to="/orders" className={({ isActive }) => (isActive ? 'active' : '')}>
            <IoMdCheckbox style={{ height: '1.2rem', width: '1.2rem' }} />
            {!isCollapsed && 'Orders'}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/users" className={({ isActive }) => (isActive ? 'active' : '')}>
            <HiUsers />
            {!isCollapsed && 'Users'}
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to="/notifications" className={({ isActive }) => (isActive ? 'active' : '')}>
            <FaBell />
            {!isCollapsed && 'Notification'}
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
});

export default Sidebar;
