import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/pages/Register/Register';
import Login from './components/pages/Login/Login';
import Dashboard from './components/pages/Dashboard/Dashboard';
import Layout from './components/Layout';
import SupplierInfoPage from './components/pages/DashPages/SupplierInfoPage';
import ProductsPage from './components/pages/DashPages/ProductPage/ProductsPage';
import UsersPage from './components/pages/DashPages/UserPage/UsersPage';
import NotificationsPage from './components/pages/DashPages/NotificationsPage';
import EditProduct from './components/pages/DashPages/ProductPage/EditProduct';
import AddProduct from './components/pages/DashPages/ProductPage/AddProduct';
import BulkUploadProduct from './components/pages/DashPages/ProductPage/BulkUploadProduct';
import { SupplierProductProvider } from './context/SupplierProductContext';
import OrdersPage from './components/pages/DashPages/OrderPage/OrdersPage';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
    const [supplierId, setSupplierId] = useState('111'); // State to store supplierId

    return (
        <SupplierProductProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        {/* Protected routes */}
                        <Route
                            path="/dashboard"
                            element={isAuthenticated ? <Dashboard supplierId={supplierId} /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/products"
                            element={isAuthenticated ? <ProductsPage /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/add-product"
                            element={isAuthenticated ? <AddProduct /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/edit-product"
                            element={isAuthenticated ? <EditProduct /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/add-bulk-product"
                            element={isAuthenticated ? <BulkUploadProduct /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/supplier-info"
                            element={isAuthenticated ? <SupplierInfoPage /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/users"
                            element={isAuthenticated ? <UsersPage /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/orders"
                            element={isAuthenticated ? <OrdersPage /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/notifications"
                            element={isAuthenticated ? <NotificationsPage /> : <Navigate to="/login" />}
                        />
                        <Route path="/product/:productId" element={<ProductsPage />} />
                    </Route>

                    {/* Public routes */}
                    <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} setSupplierId={setSupplierId} />} />
                    <Route
                        path="/login"
                        element={<Login setIsAuthenticated={setIsAuthenticated} setSupplierId={setSupplierId} />}
                    />

                    {/* Redirect unmatched paths to login */}
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </Router>
        </SupplierProductProvider>
    );
}

export default App;
