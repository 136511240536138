// OwnerProfile.js
// import React from 'react';

// function OwnerProfile() {
//     const handleLogout = () => {
//         // Logic for logging out (e.g., clearing authentication token, redirecting to login page)
//         console.log('Logging out...');
//         // Redirect to login page or clear session
//         window.location.href = '/login'; // Example redirect
//     };

//     return (
//         <div className="owner-profile-menu">
//             <ul>
//                 <li>Profile</li>
//                 <li>Change Password</li>
//                 <li onClick={handleLogout}>Logout</li>
//             </ul>
//         </div>
//     );
// }

// export default OwnerProfile;

import React from 'react';

function OwnerProfile() {
    const handleLogout = () => {
        // Remove user data from localStorage when logging out
        localStorage.removeItem('user');

        // Logic for logging out (e.g., clearing authentication token, redirecting to login page)
        console.log('Logging out...');
        
        // Redirect to login page
        window.location.href = '/login'; // Example redirect
    };

    return (
        <div className="owner-profile-menu">
            <ul>
                <li>Profile</li>
                <li>Change Password</li>
                <li onClick={handleLogout}>Logout</li>
            </ul>
        </div>
    );
}

export default OwnerProfile;
