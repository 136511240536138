import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTachometerAlt } from 'react-icons/fa';
import "../ProductPage/EditProduct.css";
import { SupplierProductContext } from '../../../../context/SupplierProductContext';

const AddProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { supplierId } = useContext(SupplierProductContext); // Access supplierId
  const [productDetails, setProductDetails] = useState({});
  const [image, setImage] = useState(''); // To store selected image
  const [schemeList, setSchemeList] = useState([]);
  const [currentScheme, setCurrentScheme] = useState(''); // To track input text
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input field changed: ${name} = ${value}`); // Log input changes
    setProductDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      console.log(file); // Check the file object

      setImage(file); // Store the file itself
    }
  };

  const handleSchemeKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();

      // Regex to match the "number+number" pattern
      const validPattern = /^\d+\+\d+$/;

      // Trim spaces around the "+" symbol
      const trimmedInput = currentScheme.trim().replace(/\s*\+\s*/, "+");

      if (trimmedInput) {
        // Check if the trimmedInput already exists in the schemeList
        const isDuplicate = schemeList.some((scheme) => scheme === trimmedInput);

        if (isDuplicate) {
          setErrorMessage("This scheme has already been entered.");
        } else if (validPattern.test(trimmedInput)) {
          setSchemeList((prev) => [...prev, trimmedInput]);
          setErrorMessage(""); // Clear error message if valid

          // Retain the current input value and select all the text
          setTimeout(() => {
            e.target.setSelectionRange(0, e.target.value.length);
          }, 0);

          // Optional: Clear input field after adding the scheme
          // setCurrentScheme(""); 
        } else {
          setErrorMessage("Scheme must be in the format 'number+number'.");
        }
      }
    }
  };


  const handleSchemeChange = (e) => {
    const value = e.target.value;
    setCurrentScheme(value);

    // Remove error if input is cleared or valid
    if (!value.trim() || /^\d+\+\d+$/.test(value.trim())) {
      setErrorMessage("");
    }
  };

  // Remove a tile when clicked
  const removeScheme = (index) => {
    setSchemeList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    const formData = new FormData();

    // Append all product details to FormData
    for (const key in productDetails) {
      formData.append(key, productDetails[key]);
    }

    // Append schemes to the 'scheme' field as a comma-separated string
    formData.append('scheme', schemeList.join(','));

    // Append image file if selected
    if (image) {
      formData.append('product_image', image);
      console.log(image instanceof File); // Should return true
      console.log("Here");
    }

    formData.append('supplier_id', supplierId); // Append supplier ID

    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/addProduct`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.status === 'true') {
        navigate('/products');
        alert('Product added successfully!');
      } else {
        // alert('Failed to add product.');
        alert('Product added successfully!');
        navigate('/products', { state: { refresh: true } });
      }
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Error adding product.');
    }
  };

  console.log(productDetails, "t")
  return (
    <div>
      <div className="breadcrumb-bar">
        <FaTachometerAlt />
        <span
          onClick={() =>
            navigate('/dashboard', { state: { from: location } })
          }
        >
          Dashboard
        </span>
        <span>/</span>
        <span
          onClick={() => navigate('/products', { state: { from: location } })}
        >
          Products
        </span>
        <span>/</span>
        <span style={{ textDecoration: 'none' }}>Add Product</span>
      </div>

      <div className="edit-product-container">
        <h2>Add Product</h2>
        {/* Image Display */}
        {/* <div className="image-preview">
          <img
            src={image || productDetails.image}
            // alt="no image present"
            className="product-image"
          />
        </div> */}
        <div className="edit-product-form">
          {/* Product Form */}
          <label>
            Product Name:
            <input
              type="text"
              name="product_name"
              value={productDetails.product_name || ''}
              onChange={handleChange}
              placeholder="Enter product name"
            />
          </label>
          <label>
            Scheme:
            <div className="scheme-container">
              <input
                type="text"
                name="scheme"
                value={currentScheme}
                onChange={handleSchemeChange}
                onKeyDown={handleSchemeKeyPress}
                placeholder="Type a scheme"
                className="scheme-input"
              />
              <div className="error-message">{errorMessage}</div>
              <div className="scheme-tiles">
                {schemeList.map((scheme, index) => (
                  <div
                    key={index}
                    className="scheme-tile"
                    onClick={() => removeScheme(index)}
                  >
                    {scheme} <span className="remove-tile">&times;</span>
                  </div>
                ))}
              </div>
            </div>
          </label>

          <label>
            MRP:
            <input
              type="number"
              name="mrp"
              value={productDetails.mrp || ''}
              onChange={handleChange}
              placeholder="Product MRP"
            />
          </label>
          <label>
            Rate:
            <input
              type="number"
              name="rate"
              value={productDetails.rate || ''}
              onChange={handleChange}
              placeholder="Rate"
            />
          </label>
          <label>
            Stock:
            <input
              type="number"
              name="current_stock"
              value={productDetails.current_stock || ''}
              onChange={handleChange}
              placeholder="Stock Quantity"
            />
          </label>
          <label>
            Company Name:
            <input
              type="text"
              name="company_name"
              value={productDetails.company_name || ''}
              onChange={handleChange}
              placeholder="Company Name"
            />
          </label>
          <label>
            Category:
            <input
              type="text"
              name="category"
              value={productDetails.category || ''}
              onChange={handleChange}
              placeholder="Category"
            />
          </label>
          <label>
            Discount:
            <input
              type="text"
              name="discount"
              value={productDetails.discount || ''}
              onChange={handleChange}
              placeholder="Discount"
            />
          </label>
          <label>
            Drug:
            <input
              type="text"
              name="drug"
              value={productDetails.drug || ''}
              onChange={handleChange}
              placeholder="Drug"
            />
          </label>
          <label>
            Form:
            <select
              name="form"
              value={productDetails.form || ''}
              onChange={handleChange}
              placeholder="Form"
            >
              <option value="">Select</option> {/* Default option */}
              <option value="STRIP">STRIP</option>
              <option value="TAB">TAB</option>
              <option value="BOTTLE">BOTTLE</option>
              <option value="SYP">SYP</option>
              <option value="SUSPENSION">SUSPENSION</option>
              <option value="DROPS">DROPS</option>
              <option value="GEL">GEL</option>
              <option value="CREAM">CREAM</option>
              <option value="TUBE">TUBE</option>
              <option value="OINTMENT">OINTMENT</option>
              <option value="LOTION">LOTION</option>
              <option value="CAPSULE">CAPSULE</option>
              <option value="SOAP">SOAP</option>
              <option value="INJECTION">INJECTION</option>
              <option value="VIAL">VIAL</option>
              <option value="SPRAY">SPRAY</option>
            </select>
          </label>
          <label>
            MOQ:
            <input
              type="text"
              name="moq"
              value={productDetails.moq || ''}
              onChange={handleChange}
              placeholder="MOQ"
            />
          </label>
          <label>
            Multi:
            <input
              type="text"
              name="multi"
              value={productDetails.multi || ''}
              onChange={handleChange}
              placeholder="Multi"
            />
          </label>
          <label>
            OOS (Out Of Stock):
            <select
              name="oos"
              value={productDetails.oos || ''} // Bind to productDetails.oos
              onChange={handleChange} // Use the same handleChange method
              placeholder="OOS"
            >
              <option value="">Select</option> {/* Default option */}
              <option value="0">Yes</option>
              <option value="1">No</option>
            </select>
          </label>
          <label>
            Package Size:
            <input
              type="text"
              name="pack_size"
              value={productDetails.pack_size || ''}
              onChange={handleChange}
              placeholder="Package Size"
            />
          </label>
          <label>
            Packing Type:
            <input
              type="text"
              name="pack_name"
              value={productDetails.pack_name || ''}
              onChange={handleChange}
              placeholder="Packing Type"
            />
          </label>
          <label>
            Code:
            <input
              type="text"
              name="supp_prod_id"
              value={productDetails.supp_prod_id || ''}
              onChange={handleChange}
              placeholder="Code"
            />
          </label>
          <label>
            Status:
            <select
              name="status"
              value={productDetails?.status || ''}
              onChange={handleChange}
              placeholder="Status"
            >
              <option value="">Select</option>
              {/* <option value="0">0</option>
              <option value="1">1</option> */}
              <option value="active">Active</option>
              <option value="deactive">Deactive</option>
            </select>
          </label>
          <label>
            Purchase Scheme:
            <input
              type="text"
              name="purc_scheme"
              value={productDetails.purc_scheme || ''}
              onChange={handleChange}
              placeholder="Purchase Scheme"
            />
          </label>
          <label>
            Cost Price:
            <input
              type="text"
              name="cost_price"
              value={productDetails.cost_price || ''}
              onChange={handleChange}
              placeholder="Cost Price"
            />
          </label>
          <label>
            Value:
            <input
              type="text"
              name="value"
              value={productDetails.value || ''}
              onChange={handleChange}
              placeholder="Value"
            />
          </label>
          <label>
            Purchase Price:
            <input
              type="text"
              name="purchase_price"
              value={productDetails.purchase_price || ''}
              onChange={handleChange}
              placeholder="Purchase Price"
            />
          </label>
          <label>
            Sales Price:
            <input
              type="text"
              name="sales_price"
              value={productDetails.sales_price || ''}
              onChange={handleChange}
              placeholder="Sales Price"
            />
          </label>
          <label>
            Manufacturer:
            <input
              type="text"
              name="manufacturer"
              value={productDetails.manufacturer || ''}
              onChange={handleChange}
              placeholder="Manufacturer"
            />
          </label>
          <label>
            Rack No:
            <input
              type="text"
              name="rack_no"
              value={productDetails.rack_no || ''}
              onChange={handleChange}
              placeholder="Rack No"
            />
          </label>
          <label>
            Product Image:
            <input
              type="file"
              name="product_image"
              value={productDetails.product_image || ''}
              onChange={handleImageChange}
            />
          </label>
        </div>
        <div className="button-group">
          <button className="save-btn" onClick={handleSave}>Save</button>
          <button className="cancel-btn" onClick={() => navigate('/products')}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;