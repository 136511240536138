import React, { createContext, useState, useEffect } from 'react';

export const SupplierProductContext = createContext();

export const SupplierProductProvider = ({ children }) => {
  const [supplierId, setSupplierId] = useState(null);
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shopName, setShopName] = useState(null);  // State to store shop_name
  const [number, setNumber] = useState(null);  // State to store shop_name
  const [orderLink, setOrderLink] = useState(null);
  const [sheetLink, setSheetLink] = useState(null);

  // Initialize supplierId and shopName from localStorage if available
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      // setSupplierId(storedUser.supplier_id);
      setShopName(storedUser.shop_name); // Set shop_name from localStorage
      setNumber(storedUser.mobile_number); // Set shop_name from localStorage
      setOrderLink(storedUser.supplier_link); // Set shop_name from localStorage
      setSheetLink(storedUser.sheet_link || ''); // Set sheetLink from localStorage or default to empty string
    }
  }, []);

  const fetchSupplierProducts = async (supplierId, perPage = 200) => {
    setLoading(true);
    setError(null);

    if (!supplierId) {
      const storedSupplierId = localStorage.getItem('supplier_id');
      if (!storedSupplierId) {
        setError('Supplier ID is not available');
        setLoading(false);
        return;
      }
      setSupplierId(storedSupplierId);
    }

    try {
      const formData = new FormData();
      formData.append('supplier_id', supplierId);
      formData.append('page', 1);
      formData.append('per_page', perPage);
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/searchSupplierProduct`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (result.status === "true" && Array.isArray(result.data)) {
        setSupplierProducts(result.data);
      } else {
        setSupplierProducts([]);
        setError(result.message || 'Failed to fetch products');
      }
    } catch (err) {
      setError('An error occurred while fetching supplier products.');
    } finally {
      setLoading(false);
    }
  };
  return (
    <SupplierProductContext.Provider
      value={{
        supplierId,
        shopName,  // Provide shopName to the context
        supplierProducts,
        loading,
        error,
        fetchSupplierProducts,
        setSupplierId,
        setShopName,
        number,
        setNumber,
        orderLink,
        setOrderLink,
        sheetLink,  // Provide sheetLink to the context
        setSheetLink, // Provide setter for sheetLink in context
      }}
    >
      {children}
    </SupplierProductContext.Provider>
  );
};