import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTachometerAlt } from 'react-icons/fa';
import "../ProductPage/EditProduct.css";

const EditProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productDetails, setProductDetails] = useState({});
  const [image, setImage] = useState(null); // To store selected image
  const [schemeList, setSchemeList] = useState([]);
  const [currentScheme, setCurrentScheme] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const product = location.state?.product;

    if (product) {
      setProductDetails(product); // Initialize form state with product data
      if (product.scheme) {
        setSchemeList(product.scheme.split(',').map((s) => s.trim()));
      }
      if (product.product_image) {
        setImage(product.product_image); // Set the initial image if available
      }
    } else {
      alert('No product data available');
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prev) => ({ ...prev, [name]: value }));
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0]; // Get the selected file
  //   if (file) {
  //     console.log(file); // Check the file object

  //     setImage(file); // Store the file itself
  //   }
  // };
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file  
    if (file) {
      console.log(file); // Check the file object  
      setImage(URL.createObjectURL(file)); // Set image preview
      setProductDetails((prev) => ({ ...prev, product_image: file })); // Store the file in productDetails
    }
  };

  const handleSchemeKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();

      // Regex to match the "number+number" pattern
      const validPattern = /^\d+\+\d+$/;

      // Trim spaces around the "+" symbol
      const trimmedInput = currentScheme.trim().replace(/\s*\+\s*/, "+");

      if (trimmedInput) {
        if (validPattern.test(trimmedInput)) {
          setSchemeList((prev) => [...prev, trimmedInput]);
          setErrorMessage(""); // Clear error message if valid
          // Retain the current input value and select all the text
          setTimeout(() => {
            e.target.setSelectionRange(0, e.target.value.length);
          }, 0);
          // setCurrentScheme(""); // Clear input field
        } else {
          setErrorMessage("Scheme must be in the format 'number+number'.");
        }
      }
    }
  };
  const handleSchemeChange = (e) => {
    const value = e.target.value;
    setCurrentScheme(value);
    if (!value.trim() || /^\d+\+\d+$/.test(value.trim())) {
      setErrorMessage("");
    }
  };

  const removeScheme = (index) => {
    setSchemeList((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    const formData = new FormData();
    for (const key in productDetails) {
      formData.append(key, productDetails[key]);
    }
    formData.append('scheme', schemeList.join(','));
    // if (image) {
    //   formData.append('image', product_image);
    // }

    // If an image is selected, append it to the FormData
    if (productDetails.product_image) {
      formData.append('image', productDetails.product_image);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/updateProduct`, {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.status === 'true') {
        alert('Product updated successfully!');
        navigate('/products', { state: { refresh: true } });
      } else {
        alert('Failed to update product.');
      }
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Error updating product.');
    }
  };

  console.log(productDetails?.oos, "ppppppp");
  // console.log(productDetails.oos ,"ttttt");
  return (
    <div>
      <div className="breadcrumb-bar">
        <FaTachometerAlt />
        <span onClick={() => navigate('/dashboard', { state: { from: location } })}>
          Dashboard
        </span>
        <span>/</span>
        <span onClick={() => navigate('/products', { state: { from: location } })}>
          Products
        </span>
        <span>/</span>
        <span style={{ textDecoration: 'none' }}>Edit Product</span>
      </div>

      <div className="edit-product-container">
        <h2>Edit Product</h2>
        <div className="image-preview">
          <img src={image || productDetails.image} className="product-image" />
        </div>
        <div className="edit-product-form">
          <label>
            Product Name:
            <input
              type="text"
              name="product_name"
              value={productDetails.product_name || ''}
              onChange={handleChange}
              placeholder="Enter product name"
            />
          </label>
          <label>
            Scheme:
            <div className="scheme-container">
              <input
                type="text"
                name="scheme"
                value={currentScheme}
                onChange={handleSchemeChange}
                onKeyDown={handleSchemeKeyPress}
                placeholder="Type a scheme"
                className="scheme-input"
              />
              <div className="error-message">{errorMessage}</div>
              <div className="scheme-tiles">
                {schemeList.map((scheme, index) => (
                  <div
                    key={index}
                    className="scheme-tile"
                    onClick={() => removeScheme(index)}
                  >
                    {scheme} <span className="remove-tile">&times;</span>
                  </div>
                ))}
              </div>
            </div>
          </label>
          <label>
            MRP:
            <input
              type="number"
              name="mrp"
              value={productDetails.mrp}
              onChange={handleChange}
              placeholder="Product MRP"
            />
          </label>
          <label>
            Rate:
            <input
              type="number"
              name="rate"
              value={productDetails.rate}
              onChange={handleChange}
              placeholder="Rate"
            />
          </label>
          <label>
            Stock:
            <input
              type="number"
              name="current_stock"
              value={productDetails.current_stock}
              onChange={handleChange}
              placeholder="Stock Quantity"
            />
          </label>
          <label>
            Company Name:
            <input
              type="text"
              // name="cmp_name"
              name="company_name"
              value={productDetails.company_name}
              onChange={handleChange}
              placeholder="Company Name"
            />
          </label>
          <label>
            Category:
            <input
              type="text"
              name="category"
              value={productDetails.category}
              onChange={handleChange}
              placeholder="Category"
            />
          </label>
          <label>
            Discount:
            <input
              type="text"
              name="discount"
              value={productDetails.discount}
              onChange={handleChange}
              placeholder="Discount"
            />
          </label>
          <label>
            Drug:
            <input
              type="text"
              name="drug"
              value={productDetails.drug}
              onChange={handleChange}
              placeholder="Drug"
            />
          </label>
          <label>
            Form:
            <select
              name="form"
              value={productDetails?.form || ''} // Show the selected value
              onChange={handleChange} // Handle value change
            >
              <option value="">Select </option>
              <option value="STRIP">STRIP</option>
              <option value="TAB">TAB</option>
              <option value="BOTTLE">BOTTLE</option>
              <option value="SYP">SYP</option>
              <option value="SUSPENSION">SUSPENSION</option>
              <option value="DROPS">DROPS</option>
              <option value="GEL">GEL</option>
              <option value="CREAM">CREAM</option>
              <option value="TUBE">TUBE</option>
              <option value="OINTMENT">OINTMENT</option>
              <option value="LOTION">LOTION</option>
              <option value="CAPSULE">CAPSULE</option>
              <option value="SOAP">SOAP</option>
              <option value="INJECTION">INJECTION</option>
              <option value="VIAL">VIAL</option>
              <option value="SPRAY">SPRAY</option>
            </select>
          </label>
          <label>
            MOQ:
            <input
              type="text"
              name="moq"
              value={productDetails.moq}
              onChange={handleChange}
              placeholder="MOQ"
            />
          </label>
          <label>
            Multi:
            <input
              type="text"
              name="multi"
              value={productDetails.multi}
              onChange={handleChange}
              placeholder="Multi"
            />
          </label>
          <label>
            OOS (Out Of Stock):
            <select
              name="oos"
              value={productDetails?.oos || ''} // Bind to productDetails.oos
              onChange={handleChange} // Use the same handleChange method
            >
              <option value="">Select</option> {/* Default option */}
              <option value="0">Yes</option>
              <option value="1">No</option>
            </select>
          </label>
          <label>
            Package Size:
            <input
              type="text"
              name="pack_size"
              value={productDetails.pack_size}
              onChange={handleChange}
              placeholder="Package Size"
            />
          </label>
          <label>
            Packing Type:
            <input
              type="text"
              name="pack_name"
              value={productDetails.pack_name}
              onChange={handleChange}
              placeholder="Packing Type"
            />
          </label>
          <label>
            Code:
            <input
              type="text"
              name="supp_prod_id"
              value={productDetails.supp_prod_id}
              onChange={handleChange}
              placeholder="Code"
            />
          </label>
          <label>
            Status:
            <select
              name="status"
              value={productDetails?.status}
              onChange={handleChange}
              placeholder="Status"
            >
              {/* <option value="">Select</option> Default option */}
              <option value="active">Active</option>
              <option value="deactive">Deactive</option>
            </select>
          </label>

          <label>
            Purchase Scheme:
            <input
              type="text"
              name="purc_scheme"
              value={productDetails.purc_scheme}
              onChange={handleChange}
              placeholder="Purchase Scheme"
            />
          </label>
          <label>
            Cost Price:
            <input
              type="text"
              name="cost_price"
              value={productDetails.cost_price}
              onChange={handleChange}
              placeholder="Cost Price"
            />
          </label>
          <label>
            Value:
            <input
              type="text"
              name="value"
              value={productDetails.value}
              onChange={handleChange}
              placeholder="Value"
            />
          </label>
          <label>
            Purchase Price:
            <input
              type="text"
              name="purchase_price"
              value={productDetails.purchase_price}
              onChange={handleChange}
              placeholder="Purchase Price"
            />
          </label>
          <label>
            Sales Price:
            <input
              type="text"
              name="sales_price"
              value={productDetails.sales_price}
              onChange={handleChange}
              placeholder="Sales Price"
            />
          </label>
          <label>
            Manufacturer:
            <input
              type="text"
              name="manufacturer"
              value={productDetails.manufacturer}
              onChange={handleChange}
              placeholder="Manufacturer"
            />
          </label>
          <label>
            Rack No:
            <input
              type="text"
              name="rack_no"
              value={productDetails.rack_no}
              onChange={handleChange}
              placeholder="Rack No"
            />
          </label>
          {/* Image Upload Field */}
          <label>
            Product Image:
            <input
              type="file"
              name="product_image"
              // value={productDetails.product_image}
              onChange={handleImageChange}
            />
          </label>
        </div>
        <div className="button-group">
          <button className="save-btn" onClick={handleSave}>Save</button>
          <button className="cancel-btn" onClick={() => navigate('/products')}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;