import React from 'react'

const SupplierInfoPage = () => {
  return (
    <div style={{display:'flex',textAlign:'center',alignItems:'center'}}>
      coming soon
    </div>
  )
}

export default SupplierInfoPage
